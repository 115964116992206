<template>
  <div
    class="container"
    :class="loading ? 'container-actives' : ''"
    v-loading="loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="tops">
      <div class="conts">创建简历</div>
    </div>
    <div class="foots">
      <div class="les">
        <div class="cts">
          <div
            class="ls"
            :class="clickActives == index ? 'actives' : ''"
            v-for="(item, index) in clickFonts"
            :key="index"
            @click="clickCv('cv' + (index + 2), index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="ris">
        <div ref="cv1" style="display: none">
          <cv1></cv1>
        </div>
        <div ref="cv2">
          <cv2 :jibenxinxi="jibenxinxi"></cv2>
        </div>
        <div ref="cv3">
          <cv3 :qiuzhi="qiuzhi"></cv3>
        </div>
        <div ref="cv4">
          <cv4 :comment="comment"></cv4>
        </div>
        <div ref="cv5">
          <cv5 :jiaoyu="jiaoyu"></cv5>
        </div>
        <div ref="cv6">
          <cv6 :peixun="peixun"></cv6>
        </div>
        <div ref="cv7">
          <cv7 :gongzuo="gongzuo"></cv7>
        </div>
        <div ref="cv8">
          <cv8 :xiangmu="xiangmu"></cv8>
        </div>
        <div ref="cv9">
          <cv9 :other="other"></cv9>
        </div>
        <div ref="cv10">
          <cv10 :addfiles="addfiles"></cv10>
        </div>
        <div ref="cv11">
          <cv11 :zpfiles="zpfiles"></cv11>
        </div>
        <div ref="cv12">
          <cv12 :wfiles="wfiles"></cv12>
        </div>
        <!-- <el-button type="primary" size="default" class="zsub">保存</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import cv1 from "../components/cv1.vue"; //简历解析
import cv2 from "../components/cv2.vue"; //基本信息
import cv3 from "../components/cv3.vue"; //求职意向
import cv4 from "../components/cv4.vue"; //自我评述
import cv5 from "../components/cv5.vue"; //教育经历
import cv6 from "../components/cv6.vue"; //培训经历
import cv7 from "../components/cv7.vue"; //工作经历
import cv8 from "../components/cv8.vue"; //项目经验
import cv9 from "../components/cv9.vue"; //其他信息
import cv10 from "../components/cv10.vue"; //附件信息
import cv11 from "../components/cv11.vue"; //作品信息
import cv12 from "../components/cv12.vue"; //荣誉资质
import { isLogins, Host } from "../comjs/com";
export default {
  name: "Cv",
  components: {
    cv1,
    cv2,
    cv3,
    cv4,
    cv5,
    cv6,
    cv7,
    cv8,
    cv9,
    cv10,
    cv11,
    cv12,
  },
  data() {
    return {
      active: 3,
      loading: false,
      clickActives: 0,
      jibenxinxi: {},
      qiuzhi: {},
      comment: "",
      jiaoyu: [],
      peixun: [],
      gongzuo: [],
      xiangmu: [],
      other: "",
      addfiles: [],
      zpfiles: [],
      wfiles:[],
      clickFonts: [
        // "简历解析",
        "基本信息",
        "求职意向",
        "自我评述",
        "教育经历",
        "培训经历",
        "工作经历",
        "项目经验",
        "其它信息",
        "附件信息",
        "作品信息",
        "荣誉资质",
      ],
    };
  },
  mounted() {
    isLogins();
    this.loading = true;
    this.$axios
      .post("/Api/user/get", {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        setTimeout(() => {
          this.loading = false;
          this.jibenxinxi = res.data.data.data;
          this.qiuzhi = res.data.data.intention;
          this.comment = res.data.data.data.comment;
          this.jiaoyu = res.data.data.educational;
          this.peixun = res.data.data.training;
          this.gongzuo = res.data.data.working;
          this.xiangmu = res.data.data.project;
          this.other = res.data.data.data.other;
          this.addfiles = [
            {
              name: res.data.data.data.realname,
              url: Host + res.data.data.data.file,
              file: res.data.data.data.file,
            },
          ];
          this.zpfiles = [
            {
              name: res.data.data.data.realname,
              url: Host + res.data.data.data.plant,
              file: res.data.data.data.plant,
            },
          ];
          this.wfiles = [
            {
              name: res.data.data.data.realname,
              url: Host + res.data.data.data.wfile,
              file: res.data.data.data.wfile,
            },
          ];
        }, 500);
      });
  },
  methods: {
    clickCv(e, index) {
      console.log(e);
      this.$nextTick(() => {
        this.$refs[e].scrollIntoView({
          behavior: "smooth",
        });
      });
      this.clickActives = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.zsub {
  position: sticky;
  bottom: 0;
  z-index: 100%;
  left:50%;
  margin-left: -20px;
}
.container-actives {
  height: 100vh;
  overflow: hidden;
}
.tops {
  @extend .minwid;
  height: 125px;
  background: #f3f8ff;
  margin-bottom: 65px;
  .conts {
    color: #323232;
    font-size: 36px;
    display: flex;
    align-items: center;
    @extend .tutWid;
    height: 100%;
  }
}
.foots {
  display: flex;
  @extend .tutWid;
  .les {
    width: 300px;
  }
  .ris {
    width: 900px;
    padding-bottom: 10px;
  }
}
.cts {
  position: fixed;
  top: 300px;
  padding-right: 25px;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 10px);
    top: 5px;
    right: 0;
    background: #e1e6f0;
  }
  .ls {
    font-size: 14px;
    color: #323232;
    cursor: pointer;
    position: relative;
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 10px;
    }
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background: #fff;
      border: 1px solid #e1e6f0;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      right: -30px;
      box-sizing: border-box;
    }
  }
  .actives {
    @extend .color;
    &::before {
      border: 3px solid #117afb;
    }
  }
}
</style>
