<template>
  <div class="container">
    <div class="conts">
      <div class="les">
        <img :src="dataImg" alt="" class="a1" />
        <div class="a2">
          <img src="../assets/img/pcimg33.png" alt="" class="b1" style="width:115px;"/>
          <div class="b2">扫码关注豪美新材招聘</div>
        </div>
      </div>
      <div class="ris">
        <div class="conta">
          <div class="title">求职者登录</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
            <el-form-item prop="phone">
              <el-input placeholder="输入手机号" v-model="ruleForm.phone">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password1">
              <el-input
                placeholder="密码"
                v-model="ruleForm.password1"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="xieyi" class="ls1">
              <el-checkbox v-model="ruleForm.xieyi"></el-checkbox>
              <div class="a1">
                我已阅读并同意
                <router-link to="/serve" target="_blank">《服务协议》</router-link>
                和
                <router-link to="/privacy" target="_blank">《隐私协议》</router-link>
              </div>
            </el-form-item>
            <el-form-item class="ls3">
              <el-button
                :disabled="disabled2"
                type="primary"
                @click="submitForm('ruleForm')"
                >登录</el-button
              >
            </el-form-item>
            <div class="ls5">
              <router-link to="/forgot" class="a1">忘记密码</router-link>
              <router-link to="/register">注册</router-link>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Host } from "../comjs/com";
export default {
  name: "Login",
  data() {
    let validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空!"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      }
      callback();
    };
    return {
      disabled1: true,
      disabled2: true,
      ruleForm: {
        phone: "",
        password1: "",
        xieyi: false,
      },
      rules: {
        phone: [
          {
            validator: validatorPhone,
            trigger: "blur",
          },
        ],
        password1: [{ required: true, message: "密码不能为空", trigger: "blur" }],
      },
      dataImg: "",
      Host: Host,
    };
  },
  mounted() {
    this.$axios.post("/Api/photo/getphoto", { id: 5 }).then((res) => {
      this.dataImg = this.Host + res.data.data.content[3].img;
    });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.xieyi) {
            this.$message({
              message: "请先阅读并同意《服务协议》和《隐私协议》",
              type: "warning",
            });
          } else {
            this.$axios
              .post("/Api/user/login", {
                phone: this.ruleForm.phone,
                password: this.ruleForm.password1,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  this.$message({
                    message: res.data.message,
                    type: "warning",
                  });
                } else if (res.data.code == 1) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                    duration: 2000,
                  });
                  setTimeout(() => {
                    this.cominfo(res.data.data.token,res.data.data.code);
                    this.$router.push("/personal");
                  }, 2000);
                } else if (res.data.code == 2) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                    duration: 2000,
                  });
                  setTimeout(() => {
                    this.cominfo(res.data.data.token,res.data.data.code);
                    this.$router.push("/info");
                  }, 2000);
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cominfo(e,getcode) {
      this.$bus.$emit("Logins", true);
      localStorage.setItem("Logins", "ture");
      localStorage.setItem("Phone", this.ruleForm.phone);
      localStorage.setItem("token", e);
      localStorage.setItem("code", getcode);
      this.$bus.$emit("Phones", this.ruleForm.phone);
    },
  },
  computed: {
    watchMore() {
      var phone = this.ruleForm.phone;
      var password1 = this.ruleForm.password1;
      return { phone, password1 };
    },
  },
  watch: {
    watchMore: function (newVar) {
      if (newVar.phone !== "" && newVar.password1 !== "") {
        this.disabled2 = false;
      } else {
        this.disabled2 = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f4f8;
  min-height: calc(100vh - 180px);
  @extend .minwid;
  overflow: hidden;
}
.conts {
  width: 1000px;
  height: 600px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(38, 89, 203, 0.06);
  margin: 80px auto;
  display: flex;
  overflow: hidden;
}
.les {
  width: 500px;
  .a1 {
    display: block;
    height: 380px;
  }
  .a2 {
    height: 220px;
    width: 100%;
    @extend .cflex;
    flex-direction: column;
    background: #cbdbee;
    .b2 {
      margin-top: 12px;
      color: #323232;
      font-size: 14px;
    }
  }
}
.ris {
  box-sizing: border-box;
  width: 500px;
  @extend .cflex;
  padding: 0 70px;
  .conta {
    width: 360px;
  }
  .title {
    color: #505050;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .ls {
    /deep/ .el-form-item__content {
      .a1 {
        width: 200px;
        margin-right: 10px;
      }
    }
  }
  .ls1 {
    margin-bottom: 20px;
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
      line-height: unset;
    }
    /deep/ .el-checkbox {
      display: flex;
      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
    .a1 {
      margin-top: -2px;
      margin-left: 10px;
      color: #787878;
      font-size: 12px;
      a {
        @extend .color;
        font-size: 12px;
      }
    }
  }
  .ls2 {
    margin-bottom: 0;
    .a1 {
      color: #767c93;
      font-size: 12px;
    }
  }
  .ls3 {
    /deep/ .el-button {
      width: 100%;
    }
  }
  .ls4 {
    @extend .cflex;
    margin-top: 20px;
    a {
      @extend .color;
      font-size: 14px;
    }
  }
  .ls5 {
    margin-top: 15px;
    @extend .cflex;
    a {
      color: #646464;
      font-size: 14px;
    }
    .a1 {
      padding-right: 15px;
      border-right: 1px solid #646464;
      margin-right: 15px;
      line-height: 14px;
    }
  }
}
/deep/ .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .el-form-item.is-error .el-input__inner {
  border-color: #dcdfe6;
}
/deep/ .el-button.is-disabled {
  color: #babdc9;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}
/deep/ .el-button--primary {
  background-color: #148ef5;
}
</style>
