<template>
  <div class="container">
    <div class="conts">
      <div>
        <p>一、保密责任人</p>
        1、凡经甲方录用（包括试用）、聘用（请）的乙方相关人员都必须签订本协议，有义务为甲方保密，并无条件遵守本协议。<br/><br/>
        2、本协议适用于曾在甲方工作及因各种原因已离开甲方并签订本协议的乙方人员。<br/><br/>
        3、本协议适用于确有需要，并与甲方签订过本协议的所有非甲方人员。<br/><br/>
        4、乙方一旦在本协议上签字，即视为责任人，有履行本协议之义务，如无正当理由不得单方解除协议。<br/>
        <p>二、保密范围</p>
        甲乙双方确认，乙方为甲方承担商业保密义务的包括但不限于以下内容：<br/><br/>
        1、乙方利用在甲方的工作机会，履行本职工作完成的技术成果、积累的经营信息及因工作关系而获得、交换的保密信息等无形资产归甲方所有，为甲方的商业保密。<br/><br/>
        2、涉及甲方所有正在进行生产或开发的所有产品，曾经进行生产，因各种原因现已停止生产的产品。<br/><br/>
        3、涉及甲方所有商业机密，包括产品质量、产量、价格、商业客户、原材料的质量标准、供应厂家、技术方案、工程设计、电路设计、制造方法、配方、工艺流程、技术指标、计算机软件、数据库、试验结果、图纸、样品、样机、模型、模具、操作手册、技术文档、涉及商业秘密的业务函电等。<br/><br/>
        4、涉及甲方的生产机密，包括生产工艺、原材料规格等技术指标。<br/><br/>
        5、涉及甲方的生产设备，包括设备造型、生产厂家、材质、价格和设备的维护和维修方法等。<br/><br/>
        6、涉及甲方的所有商业决策及经营活动信息、文件、产品开发计划等。<br/><br/>
        7、涉及甲方的客户名单、营销计划、采购资料、定价政策、不公开的财务资料、进货渠道、产销策略等。<br/><br/>
        8、涉及甲方未公布的人事调动、人事任免、机构的设置、编制、人员名册和统计表、奖惩资料、考核资料；各级员工的个人薪金收入情况；及甲方具有保密级别的文件、资料、会议记录、信件、方案、投标书、甲方客户的资料及财产；销售合同、销售网络、渠道等。<br/><br/>
        9、甲方依照法律规定或有关协议的约定，对外承担保密义务的事项。<br/>
        <p>三、保密义务</p>
        1、乙方为本协议所称的保密义务人，保密义务人是指为甲方提供相关服务而知悉甲方商业秘密，并且在甲方领取报酬或工资的人员。<br/><br/>
        2、甲方向保密义务人支付的报酬或工资中已包含保密费，此处不再重复支付。<br/><br/>
        3、对甲方的商业秘密，乙方承担以下义务：<br/><br/>
        （1）不得刺探与本职工作或本身业务无关的甲方商业秘密；<br/><br/>
        （2）不得向任何第三人披露甲方的商业秘密。<br/><br/>
        （3）不得使任何第三人(包括甲方单位内部员工)获得、使用或计划使用甲方商业秘密信息，即除了得到甲方单位指示和在业务需要的程度范围内，向应该知道上述内容的单位内部员工或本单位外业务单位进行保密内容交流外，不得直接或间接向单位内部、外部的人员泄露商业秘密信息。<br/><br/>
        4、乙方在甲方工作期间不得组织、计划组织以及参加任何与公司竞争的企业或活动；在劳动合同终止后，不得直接或间接地劝诱、帮助他人劝诱甲方内掌握商业秘密的员工离开甲方单位；在劳动合同终止后一年内，不得组建、参与或就业于与甲方有竞争关系的公司或单位。<br/><br/>
        5、不得允许（出借、赠与、出租、转让等方式处理甲方商业秘密的行为皆属于“允许”）或协助任何第三人使用甲方商业秘密信息。<br/><br/>
        6、乙方不得为自己使用或计划使用甲方的商业秘密谋取私人利益。<br/><br/>
        7、不得复制或公开包含甲方单位商业秘密内容的文件、信函、正本、副本、光盘等；<br/><br/>
        8、因工作保管、接触的有关单位的文件应妥善保存，未经许可不得超出工作范围使用，如发现商业秘密被泄露或因自己过失泄露的，应采取有效措施防止泄密进一步扩大，并及时向甲方报告；<br/><br/>
        9、乙方同意因职务创造和构思的有关技术秘密或经营秘密，将向甲方及时汇报，并以书面形式作出报告，该职务成果归属甲方；<br/><br/>
        10、在商业秘密的个别部分或个别要素已被公知，但尚未使商业秘密的其他部分或整体成为公知知识，以致商业秘密没有丧失价值的情况下，乙方应承担仍属秘密信息部分的保密义务，不得使用该部分信息或诱导第三人通过收集公开信息以整理出甲方的商业秘密。<br/>
        <p>四、保密期限</p>
        经甲乙双方确认，乙方的保密义务自甲方盖章和乙方签字之日起开始，至上述商业秘密公开或被公众知悉时止。乙方的保密义务并不因劳动关系的解除而免除，协议期限为聘用合同期内及解除聘用合同后两年内。<br/>
       <p> 五、违约责任</p>
        甲乙双方商定，如乙方违反上述各项义务而损害甲方利益，按照以下方法承担违约责任：<br/><br/>
        1、若乙方不履行本协议所规定的保密义务，应一次性向甲方支付违约金12个月的工资，计人民币元；<br/><br/>
        2、若因乙方前款的违约行为造成甲方损失的，乙方应承担赔偿责任（如乙方已经支付违约金的，应予以扣除），具体损失赔偿标准为：<br/><br/>
        （1）损失赔偿额为甲方因乙方的违约行为所受到的实际经济损失，包括甲方为开发、培植有关商业秘密所投入的费用，因乙方的违约行为导致甲方产品销售量减少的金额，以及依靠商业秘密取得的利润减少金额等。<br/><br/>
        （2）依照（1）款计算方法难以计算的，损失赔偿额为乙方因违约行为所获得的全部利润。<br/><br/>
        （3）甲方因调查乙方违约行为而支付的合理费用，由乙方承担。<br/><br/>
        （4）因乙方违约行为侵犯了甲方商业秘密权利的，甲方可选择根据本协议中条款要求乙方承担违约责任，也可以根据国家法律、法规要求乙方承担侵权责任。<br/><br/>
        3、乙方如有违反本协议之行为，甲方将依照国家的有关法规和甲方的规章制度，视其侵权及造成的损失程度，给予处罚直至追究其法律责任。<br/><br/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f4f8;
  @extend .minwid;
  overflow: hidden;
}
.conts {
  @extend .tutWid;
  background: #fff;
  height: calc(100vh - 260px);
  box-sizing: border-box;
  padding: 16px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
  @extend .scrollbar;
  p{font-weight: bold;font-size: 18px;}
}
</style>
