<template>
  <div class="container">
    <p class="a1">404页面</p>
    <div class="a2">{{ times }}s后跳转首页</div>
  </div>
</template>
<script>
export default {
  name: "404",
  data() {
    return {
      times: 5,
    };
  },
  methods: {
    countDown() {
      let that = this;
      that.times--;
    },
  },
  mounted() {
    let that = this;
    setInterval(that.countDown, 1000);
  },
  watch: {
    times: function (newVal, oldVal) {
      if (newVal == 0) {
        this.$router.push("/index");
      }
      console.log(oldVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @extend .cflex;
  flex-direction: column;
  height: 100vh;
  font-size: 20px;
  p {
    font-size: 40px;
  }
}
</style>
