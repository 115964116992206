<template>
  <div class="container">
    <el-dialog title="考题二维码" :visible.sync="dialog">
      <img
        :src="showImgs"
        alt=""
        style="width: 500px; display: block; margin: 0 auto"
      />
    </el-dialog>
    <div class="conts">
      <div class="navs">
        <a href="/">首页&nbsp;&nbsp;>&nbsp;&nbsp;</a>
        <router-link to="/personal">个人中心</router-link>
      </div>
      <div class="cts">
        <div class="les">
          <div class="conts1">
            <div class="com1">
              <div class="a1">我的信息</div>
              <div>
                <router-link to="/yulan" class="a2">简历预览</router-link>
                <router-link to="/cv" class="a2">创建简历</router-link>
              </div>
            </div>
            <div class="foots">
              <img src="../assets/img/pcimg32.png" alt="" class="a1" />
              <div class="a2">
                <div class="ls">
                  <div class="b1">姓名</div>
                  <div class="b2">{{ getinfo1.realname }}</div>
                </div>
                <div class="ls">
                  <div class="b1">电话</div>
                  <div class="b2">{{ getinfo1.phone }}</div>
                </div>
                <div class="ls">
                  <div class="b1">邮箱</div>
                  <div class="b2">{{ getinfo1.email }}</div>
                </div>
              </div>
              <!-- <div class="a2">
                <div class="ls">
                  <div class="b1">毕业院校</div>
                  <div class="b2">佛山科技学院</div>
                </div>
                <div class="ls">
                  <div class="b1">专业</div>
                  <div class="b2">计算机软件</div>
                </div>
                <div class="ls">
                  <div class="b1">当前公司</div>
                  <div class="b2"></div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="conts1 conts2">
            <div class="com1">
              <div class="a1">我的投递记录</div>
            </div>
            <div class="foots">
              <div class="kuai-mids">
                <el-table :data="tableData" style="width: 100%">
                  <el-table-column label="岗位名称" prop="title" width="150">
                  </el-table-column>
                  <el-table-column prop="department" label="应聘事业部">
                  </el-table-column>
                  <el-table-column label="简历投递时间">
                    <template slot-scope="scope">{{
                      scope.row.createTime | tmoment("YYYY-MM-DD")
                    }}</template>
                  </el-table-column>
                  <el-table-column label="应聘状态" width="150">
                    <template slot-scope="scope">
                      <div v-if="scope.row.status == 1">审核中</div>
                      <div
                        v-if="scope.row.status == 2"
                        @click="dialogClick(scope.row.questionImg)"
                        style="cursor: pointer"
                      >
                        已查看
                        点击显示考题二维码<br />
                      </div>
                      <!-- <div v-if="scope.row.status == 2">已查看</div> -->
                      <div v-if="scope.row.status == 3">已查看</div>
                      <div v-if="scope.row.status == 4">
                        通过<br>
                        <a target="_blank" :href="scope.row.letter">点击查看录用函</a>
                      </div>
                      <div v-if="scope.row.status == 9">已查看</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="备注" width="150">
                    <template slot-scope="scope">
                      <div v-if="scope.row.status == 1">请耐心等通知</div>
                      <div v-if="scope.row.status == 2">
                        {{ scope.row.questionRemark }}
                      </div>
                      <div v-if="scope.row.status == 3">
                        {{ scope.row.noticeRemark }}
                      </div>
                      <div v-if="scope.row.status == 4">
                        {{ scope.row.passRemark }}
                      </div>
                      <div v-if="scope.row.status == 9">
                        {{ scope.row.nopassRemark }}
                      </div>
                      <div v-if="scope.row.address">
                        面试地址：{{ scope.row.address }}
                      </div>
                      <div v-if="scope.row.usernoticeTime">
                        面试时间：{{ scope.row.usernoticeTime }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        @click.native.prevent="
                          deleteRow(scope.$index, scope.row.id, tableData)
                        "
                        type="text"
                        size="small"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="kuai-foots">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total2"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div class="conts1 conts3">
            <div class="com1">
              <div class="a1">我的收藏</div>
            </div>
            <div class="foots">
              <!-- <el-row class="ls">
                <el-col :span="18">
                  <div class="a1">
                    <router-link class="b1" to="/">MBSO</router-link>
                    <div class="b2">2022-06-21发布</div>
                  </div>
                  <div class="a2">
                    <div class="b1">生产制造</div>
                    <div class="b1">微波和清洁事业部</div>
                    <div class="b1">7-15 年</div>
                    <div class="b1">本科及以上</div>
                    <div class="b1">广东省-佛山</div>
                  </div>
                </el-col>
                <el-col :span="6" class="ris">
                  <el-button type="primary" :disabled="true" @click="toudi"
                    >已过期</el-button
                  >
                  <el-button>取消收藏</el-button>
                </el-col>
              </el-row> -->
              <el-row
                class="ls noguoqi"
                v-for="(item, index) in tableData2"
                :key="index"
              >
                <el-col :span="18">
                  <div class="a1">
                    <router-link
                      v-if="item.cateid == 1"
                      class="b1"
                      :to="{ path: '/campus-content', query: { id: item.id } }"
                      >{{ item.title }}</router-link
                    >
                    <router-link
                      v-if="item.cateid == 2"
                      class="b1"
                      :to="{ path: '/society-content', query: { id: item.id } }"
                      >{{ item.title }}</router-link
                    >
                    <router-link
                      v-if="item.cateid == 3"
                      class="b1"
                      :to="{
                        path: '/internal-content',
                        query: { id: item.id },
                      }"
                      >{{ item.title }}</router-link
                    >
                    <router-link
                      v-if="item.cateid == 4"
                      class="b1"
                      :to="{ path: '/master-content', query: { id: item.id } }"
                      >{{ item.title }}</router-link
                    >
                    <div class="b2">
                      {{ item.createTime | tmoment("YYYY-MM-DD") }}发布
                    </div>
                  </div>
                  <div class="a2">
                    <div class="b1">{{ item.jobCate }}</div>
                    <div class="b1">{{ item.department }}</div>
                    <div class="b1">{{ item.province }}-{{ item.city }}</div>
                  </div>
                </el-col>
                <el-col :span="6" class="ris">
                  <el-button
                    type="primary"
                    :disabled="false"
                    @click="toudi(item.id)"
                    >投递</el-button
                  >
                  <el-button @click="quxiao(item.id)">取消收藏</el-button>
                </el-col>
              </el-row>
              <div class="kuai-foots" style="padding-bottom: 30px">
                <el-pagination
                  @size-change="handleSizeChange1"
                  @current-change="handleCurrentChange1"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total3"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
        <ContentLeft :geturl="geturl" :cateid="1"></ContentLeft>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogins, Host } from "../comjs/com";
import ContentLeft from "../components/Content_Left.vue";
export default {
  name: "Personal",
  components: {
    ContentLeft,
  },
  data() {
    return {
      dialog: false,
      geturl: "/campus",
      getinfo1: "",
      getinfo2: "",
      limit2: 10,
      page2: 1,
      total2: 1,
      current_page2: 1,
      getinfo3: "",
      limit3: 10,
      page3: 1,
      total3: 1,
      current_page3: "",
      currentPage4: 1,
      currentPage5: 1,
      // 1是申请中，2是发送考题，3通知面试，4通过，9拒绝
      tableData: [
        // {
        //   id: 1,
        //   title: "投资管理专员",
        //   department: "家用空调事业部",
        //   createTime: 1659255198,
        //   status: 1,
        // },
      ],
      tableData2: [],
      showImgs: "",
    };
  },
  mounted() {
    isLogins();
    this.$axios
      .all([
        this.$axios.post("/Api/user/getSimMember", {
          token: localStorage.getItem("token"),
        }),
      ])
      .then(
        this.$axios.spread((res) => {
          if (res.data.code == 1) {
            this.getinfo1 = res.data.data;
          }
        })
      );
    this.getlist2();
    this.getlist3();
  },
  methods: {
    dialogClick(e) {
      this.showImgs = Host + e;
      this.dialog = true;
    },
    getlist2() {
      this.$axios
        .post("/Api/post/getApplyList", {
          token: localStorage.getItem("token"),
          limit: this.limit2,
          page: this.page2,
        })
        .then((res) => {
          this.tableData = [];
          this.total2 = res.data.data.total;
          this.current_page2 = res.data.data.current_page;
          let lindatas = res.data.data.data;
          for (let i = 0; i < lindatas.length; i++) {
            this.tableData.push({
              id: lindatas[i]["id"],
              title: lindatas[i]["title"],
              department: lindatas[i]["department"],
              createTime: lindatas[i]["createTime"],
              status: lindatas[i]["paStatus"],
              questionImg: lindatas[i]["questionImg"],
              questionRemark: lindatas[i]["questionRemark"],
              noticeRemark: lindatas[i]["noticeRemark"],
              passRemark: lindatas[i]["passRemark"],
              nopassRemark: lindatas[i]["nopassRemark"],
              letter:lindatas[i]["letter"],
            });
          }
        });
    },
    getlist3() {
      this.$axios
        .post("/Api/post/getCollectionList", {
          token: localStorage.getItem("token"),
          limit: this.limit3,
          page: this.page3,
        })
        .then((res) => {
          this.tableData2 = [];
          this.total3 = res.data.data.total;
          this.current_page3 = res.data.data.current_page;
          let lindatas = res.data.data.data;
          for (let i = 0; i < lindatas.length; i++) {
            this.tableData2.push({
              id: lindatas[i]["id"],
              title: lindatas[i]["title"],
              department: lindatas[i]["department"],
              jobCate: lindatas[i]["jobCate"],
              createTime: lindatas[i]["createTime"],
              cateid: lindatas[i]["cateid"],
              province: lindatas[i]["province"],
              city: lindatas[i]["city"],
            });
          }
        });
    },
    deleteRow(index, id, rows) {
      // rows[index]["id"] 删除的id
      rows.splice(index, 1);
      this.limit2 = 10;
      this.page2 = 1;
      console.log(id);
      this.$axios
        .post("/Api/post/delApplyPosts", {
          id: id,
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 1) {
            this.$message.success("删除成功");
          } else {
            this.$message("删除失败");
          }
          this.getlist2();
        });
    },
    handleSizeChange(val) {
      this.limit2 = val;
      this.getlist2();
    },
    handleCurrentChange(val) {
      this.page2 = val;
      this.getlist2();
    },
    toudi(e) {
      this.$axios
        .post("/Api/post/applyPosts", {
          id: e,
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success(res.data.message);
          } else {
            this.$message({
              message: res.data.message,
            });
          }
        });
    },
    quxiao(e) {
      this.$axios
        .post("/Api/post/collection", {
          id: e,
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success(res.data.message);
          } else {
            this.$message.success(res.data.message);
            this.limit3 = 10;
            this.page3 = 1;
            this.getlist3();
          }
        });
    },
    handleSizeChange1(val) {
      this.limit3 = val;
      this.getlist3();
    },
    handleCurrentChange1(val) {
      this.page3 = val;
      this.getlist3();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @extend .minwid;
  background: #f2f4f8;
  .conts {
    @extend .tutWid;
  }
}
.navs {
  display: flex;
  line-height: 60px;
  a {
    color: #646464;
    font-size: 14px;
  }
}
.cts {
  @extend .fsb;
  align-items: flex-start;
  .les {
    width: 845px;
  }
}
.conts1 {
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 20px;
  .foots {
    padding: 35px 40px;
    display: flex;
    align-items: center;
    .a1 {
      width: 80;
      height: 80;
    }
    .a2 {
      width: 300px;
      .ls {
        font-size: 14px;
        display: flex;
        color: #292e41;
        line-height: 26px;
        .b1 {
          width: 80px;
          margin-right: 8px;
          text-align: right;
          color: #a6adc2;
        }
      }
    }
  }
}
.conts2 {
  .foots {
    display: block;
    padding: 20px 20px 35px;
  }
}
.conts3 {
  margin-bottom: 40px;
  .foots {
    padding: 0 20px;
    display: block;
  }
  .ls {
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    background: #fafafa;
    &:hover .a1 .b1 {
      @extend .color;
    }
    .a1 {
      display: flex;
      align-items: center;
      height: 24px;
      .b1 {
        color: #a6adc2;
        font-size: 14px;
        font-weight: bold;
      }
      .b2 {
        color: #a6adc2;
        font-size: 14px;
        margin-left: 20px;
      }
    }
    .a2 {
      display: flex;
      align-items: center;
      width: 100%;
      height: 24px;
      .b1 {
        color: #a6adc2;
        font-size: 14px;
        padding-right: 10px;
        margin-right: 10px;
        position: relative;
        &:not(:nth-last-of-type(1))::before {
          content: "";
          position: absolute;
          top: 4px;
          right: 0;
          background: #a6adc2;
          width: 1px;
          height: 12px;
        }
      }
    }
    .ris {
      @extend .fsb;
    }
    /deep/ .el-button {
      width: 80px;
      height: 34px;
      box-sizing: border-box;
      padding: 0;
      span {
        font-size: 14px;
      }
    }
    /deep/ .el-button--primary {
      &:hover {
        background: #20a0ff;
      }
    }
    /deep/ .is-disabled {
      background: #cbced8;
      border-color: #cbced8;
      &:hover {
        background: #cbced8;
      }
    }
    /deep/ .el-button--default:hover {
      border: 1px solid #dcdfe6;
      background: #fff;
      color: #606266;
    }
  }
  .noguoqi {
    background: none;
  }
}
.com1 {
  @extend .fsb;
  flex-direction: row;
  padding: 0 20px;
  height: 50px;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
  .a1 {
    color: #292e41;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .a2 {
    color: #148ef5;
    font-size: 14px;
    margin-right: 10px;
  }
}
.kuai-mids {
  width: 100%;
  /deep/ .el-table {
    border: 1px solid #dfe6ec;
    border-bottom: none;
    th > .cell {
      font-weight: normal;
    }
    thead {
      tr,
      th {
        background: #eef1f6 !important;
      }
    }
    tr,
    th,
    td,
    a {
      text-align: center !important;
      color: #1f2d3d;
    }
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #e6f6ff !important;
  }
  /deep/ .el-table__row:hover div,
  .el-table__row:hover a {
    color: #148ef5;
  }
  .tab-hot {
    margin-left: 10px;
    padding: 1px;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    font-size: 12px;
  }
}
.kuai-foots {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
