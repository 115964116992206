<template>
  <div>
    <div class="tops">
      <div class="conts">个人信息</div>
    </div>
    <div class="foots">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item name="1" class="t">
          <template slot="title">
            <img src="../assets/img/pcimg31.png" alt="" class="a1" />
            <div class="a2">基本信息</div>
          </template>
          <div class="cts">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <div class="ls1">
                <div class="les">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>
                  <el-form-item label="性别" prop="gender" class="a1">
                    <el-radio-group v-model="ruleForm.gender">
                      <el-radio label="1" border>男</el-radio>
                      <el-radio label="2" border>女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div class="ris">
                  <el-form-item label="个人照片" class="a1" prop="upimg">
                    <el-upload
                      action=""
                      class="avatar-uploader"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      :http-request="uploadImgList"
                      :on-exceed="handleExceed"
                      v-model="ruleForm.upimg"
                    >
                      <img
                        width="100%"
                        v-if="dialogImageUrl"
                        :src="dialogImageUrl"
                        alt=""
                      />
                      <i class="el-icon-plus" v-else></i>
                    </el-upload>
                  </el-form-item>
                  <div class="fonts">照片格式：jpg/png，建议使用标准2寸照。</div>
                </div>
              </div>
              <el-form-item label="籍贯" prop="hometown">
                <el-input v-model="ruleForm.hometown"></el-input>
              </el-form-item>
              <el-form-item label="证件号码" prop="idcard">
                <el-input v-model="ruleForm.idcard"></el-input>
              </el-form-item>
              <el-form-item prop="data" label="出生日期" class="ls2">
                <el-date-picker
                  v-model="ruleForm.data"
                  type="date"
                  placeholder="请选择"
                ></el-date-picker>
              </el-form-item>
              <div class="ls3">
                <el-form-item label="手机号码" prop="phone">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
              </div>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- <el-cascader :options="options" v-model="values">
      <template slot-scope="{ data }">
        <span>{{ data.value }}</span>
      </template>
    </el-cascader> -->
  </div>
</template>

<script>
import { isLogins, Host } from "../comjs/com";
// import cityData from "../comjs/citydata";
export default {
  name: "Info",
  data() {
    let validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空!"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      }
      callback();
    };
    return {
      activeNames: ["1"],
      dialogImageUrl: "",
      dialogVisible: false,
      // options: cityData,
      // values: "",
      ruleForm: {
        name: "",
        gender: "",
        upimg: "",
        hometown: "",
        idcard: "",
        data: "",
        phone: "",
        email: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatorPhone,
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] },
        ],
        gender: [{ required: true, message: "请选择您的性别", trigger: "change" }],
        hometown: [
          {
            required: true,
            message: "籍贯不能为空",
            trigger: "blur",
          },
        ],
        // idcard: [
        //   {
        //     required: true,
        //     message: "证件号码不能为空",
        //     trigger: "blur",
        //   },
        // ],
        data: [
          {
            required: true,
            message: "出生日期不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    // localStorage.removeItem("Logins");
    isLogins();
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    uploadImgList(params) {
      const file = params.file,
        form = new FormData();
      form.append("file", file);
      form.append("token", localStorage.getItem("token"));
      this.$axios.post("/Api/upload/index", form).then((res) => {
        this.dialogImageUrl = Host + res.data.data.file;
      });
    },
    handleAvatarSuccess(res, file) {
      // var imgs = res.data.filename;
      console.log(res, file);
      // if (imgs) {
      //   this.ruleForm.upimg = res.data.filename;
      // }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPng) {
        this.$message.warning("只能上传图片！");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传头像图片大小不能超过 2MB!");
        return false;
      }
    },
    handleExceed() {
      this.$message.warning("当前限制上传 1 张图片，要替换的话，请先删除已上传的");
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 性别，男是1，女是2
          this.$axios
            .post("/Api/user/edit", {
              realname: this.ruleForm.name,
              gender: this.ruleForm.gender,
              avatarUrl: this.dialogImageUrl,
              native_place: this.ruleForm.hometown,
              identification: this.ruleForm.idcard,
              birthday: this.ruleForm.data,
              phone: this.ruleForm.phone,
              email: this.ruleForm.email,
              token: localStorage.getItem("token"),
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: res.data.message,
                  duration: 2000,
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push("/personal");
                }, 2000);
              } else {
                this.$message({
                  message: res.data.message,
                  duration: 2000,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tops {
  @extend .minwid;
  height: 125px;
  background: #f3f8ff;
  margin-bottom: 65px;
  .conts {
    color: #323232;
    font-size: 36px;
    display: flex;
    align-items: center;
    @extend .tutWid;
    height: 100%;
  }
}
.foots {
  @extend .tutWid;
  width: 600px;
  min-height: calc(100vh - 290px);
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 100px;
    }
  }
}
.cts {
  margin-top: 75px;
  .ls1 {
    @extend .fsb;
    align-items: flex-start;
    margin-bottom: 20px;
    .les {
      width: 290px;
    }
    .ris {
      width: 270px;
      .fonts {
        font-size: 13px;
        color: #969696;
      }
    }
    .a1 {
      /deep/ .el-form-item__label {
        text-align: left;
        float: unset;
      }
      /deep/ .el-upload-list__item-thumbnail {
        object-fit: scale-down;
      }
      /deep/ .el-form-item__content {
        .el-upload--picture-card {
          width: 118px;
        }
        .el-upload-list {
          display: none !important;
        }
        & > div:nth-of-type(1) {
          display: flex;
        }
      }
    }
  }
  .ls2 {
    display: block;
    /deep/ .el-form-item__label {
      display: block;
      float: unset;
      text-align-last: left;
    }
    /deep/ .el-date-editor.el-input {
      width: 100%;
    }
  }
  .ls3 {
    @extend .fsb;
    /deep/ .el-form-item {
      width: 290px;
    }
  }
}
.avatar-uploader {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  /deep/ .el-upload--text {
    @extend .cflex;
    width: 100%;
  }
}
.avatar-uploader img {
  @extend .imgcontain;
}
.avatar-uploader i {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
