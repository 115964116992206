<template>
  <div class="container">
    <el-dialog :title="addname" :visible.sync="vis" :before-close="close" width="50%">
      <div class="addcss1" v-if="isShowFirst">
        <div
          class="a1"
          v-for="(item, index) in zhanshi"
          :key="index"
          @click="show2(item.children, item.value)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="addcss1" v-else>
        <div
          class="a1"
          v-for="(item, index) in zhanshi"
          :key="index"
          @click="show3(item.value)"
        >
          {{ item.value }}
        </div>
      </div>
    </el-dialog>
    <div class="conts">
      <div class="kuai">
        <div class="list" style="flex-wrap: wrap" v-if="zhanshilin.length > 0">
          <div class="les">已选工作地：</div>
          <div class="addcss2">
            <div v-for="(item, index) in zhanshilin" :key="index" class="a1">
              {{ item.a1 }}-{{ item.a2 }}-{{ item.a3 }}
              <i class="el-icon-close" @click="delZhan(index)"></i>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="les">工作地点</div>
          <div class="contris">
            <div class="ris">
              <div class="a1">国内</div>
              <div class="a2">
                <div
                  v-for="(item, index) in allCity"
                  :key="index"
                  @click="show1(item.children, item.value)"
                >
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="list list1">
          <div class="les">岗位类别</div>
          <div class="ris">
            <el-checkbox-group v-model="checkList2" @change="change2">
              <el-checkbox
                :label="item.id"
                v-for="(item, index) in jobcatelist"
                :key="index"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="list list1 list2">
          <div class="les">事业部</div>
          <div class="ris">
            <el-checkbox-group v-model="checkList3" @change="change3">
              <el-checkbox
                :label="item.id"
                v-for="(item, index) in departmentlist"
                :key="index"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="list list1 list3">
          <div class="les">职位</div>
          <div class="ris">
            <el-checkbox-group v-model="checkList4" @change="change4">
              <el-checkbox label="1">热招</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="kuai" style="margin-top: 20px">
        <div class="kuai-top">
          <div class="les">
            <div class="a1">岗位信息</div>
            <div class="a2">job Information</div>
          </div>
          <div class="ris">
            <div class="a1">
              <el-input v-model="words" placeholder="请输入岗位名称"></el-input>
            </div>
            <el-button type="primary" class="b1" @click="seachClick">查询</el-button>
            <el-button class="b2" @click="recharge">重置</el-button>
          </div>
        </div>
        <div class="kuai-mids">
          <el-table :data="tableData" style="width: 100%" @row-click="testclick">
            <el-table-column label="工作地点">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.address[0] + "-" + scope.row.address[1] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="岗位名称" width="310">
              <template slot-scope="scope">
                <span>{{ scope.row.title }}</span>
                <span class="tab-hot" v-if="scope.row.ishot == 1">hot</span>
              </template>
            </el-table-column>
            <el-table-column prop="department" label="事业部"> </el-table-column>
            <el-table-column prop="jobCate" label="岗位类别"> </el-table-column>
            <el-table-column prop="wage" label="薪资"> </el-table-column>
            <el-table-column label="发布/刷新时间">
              <template slot-scope="scope">{{
                scope.row.createTime | tmoment("YYYY-MM-DD")
              }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div class="kuai-foots">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityData from "../comjs/citydata";
export default {
  name: "Master",
  data() {
    return {
      checkList1: [],
      checkList2: [],
      checkList3: [],
      checkList4: [],
      allCity: cityData,
      jobcatelist: "",
      departmentlist: "",
      tableData: [
        // {
        //   title: "投资管理专员",
        //   department: "家用空调事业部",
        //   jobCate: "战略运营",
        //   address: ["广东", "佛山"],
        //   createTime: "2022-07-04",
        //   ishot: 1,
        // },
      ],
      currentPage4: 1,
      total: 0,
      limit: 10,
      cateid: 4,
      words: "", //搜索关键词
      province: "", // 市
      jobCate: "", //岗位类别
      department: "", //事业部
      ishot: 0, //是否为热招，1为热招
      page: 1, //跳转第几页

      vis: false,
      addname: "",
      zhanshi: [],
      isShowFirst: true,
      lins1: "",
      lins2: "",
      zhanshilin: [],
    };
  },
  mounted() {
    this.$axios
      .all([
        this.$axios.post("/Api/post/jobCateList"),
        this.$axios.post("/Api/post/departmentList"),
      ])
      .then(
        this.$axios.spread((res1, res2) => {
          this.jobcatelist = res1.data.data;
          this.departmentlist = res2.data.data;
        })
      );
    this.getlistdatas();
  },
  methods: {
    testclick(e){
      this.$router.push({path: '/master-content', query: { id: e.id } })
    },
    delZhan(e) {
      this.zhanshilin.splice(e, 1);
      this.checkList1.splice(e, 1);
    },
    show1(e, name) {
      this.addname = name;
      this.vis = true;
      this.zhanshi = e;
      this.lins1 = name;
    },
    show2(e, name) {
      this.addname = name;
      this.zhanshi = e;
      this.isShowFirst = false;
      this.lins2 = name;
    },
    show3(e) {
      this.isShowFirst = true;
      this.vis = false;
      this.checkList1.push(e);
      this.zhanshilin.push({
        a1: this.lins1,
        a2: this.lins2,
        a3: e,
      });
      this.lins1 = "";
      this.lins2 = "";
    },
    close(done) {
      if (!this.isShowFirst) {
        this.$confirm("确定不选择区县吗")
          .then(() => {
            this.isShowFirst = true;
            done();
            this.lins1 = "";
            this.lins2 = "";
          })
          .catch(() => {});
      } else {
        done();
        this.isShowFirst = true;
        this.lins1 = "";
        this.lins2 = "";
      }
    },
    getlistdatas() {
      this.$axios
        .post("/Api/post/postsList", {
          cateid: this.cateid,
          limit: this.limit,
          words: this.words,
          province: this.checkList1,
          jobCate: this.checkList2,
          department: this.checkList3,
          ishot: this.ishot,
          page: this.page,
        })
        .then((res) => {
          this.total = res.data.data.total; //总数量
          this.currentPage4 = res.data.data.current_page; //当前第几页
          console.log(res.data.data.data);
          let alldatas = res.data.data.data;
          this.tableData = [];
          for (let i = 0; i < alldatas.length; i++) {
            this.tableData.push({
              id: alldatas[i].id,
              title: alldatas[i].title,
              department: alldatas[i].department,
              jobCate: alldatas[i].jobCate,
              address: [alldatas[i].province, alldatas[i].city],
              createTime: alldatas[i].createTime,
              ishot: alldatas[i].ishot,
              wage:alldatas[i].wage
            });
          }
        });
    },
    change1(val) {
      this.checkList1 = val;
      console.log(this.checkList1);
    },
    change2(val) {
      this.checkList2 = val;
      console.log(this.checkList2);
    },
    change3(val) {
      this.checkList3 = val;
      console.log(this.checkList3);
    },
    change4(val) {
      if (val[0]) {
        this.ishot = val[0];
      } else {
        this.ishot = 0;
      }
    },
    recharge() {
      this.checkList1 = [];
      this.checkList2 = [];
      this.checkList3 = [];
      this.checkList4 = [];
      this.seachKey = "";
      this.words = "";
      this.zhanshilin = [];
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getlistdatas();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getlistdatas();
    },
    seachClick() {
      this.getlistdatas();
    },
  },
};
</script>

<style lang="scss" scoped>
.addcss1 {
  display: flex;
  flex-wrap: wrap;
  .a1 {
    min-width: 90px;
    margin: 0 15px 10px 0;
    cursor: pointer;
  }
}
.addcss2 {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  .a1 {
    color: #606266;
    font-weight: 500;
    font-size: 14px;
    margin-right: 20px;
    position: relative;
    margin-bottom: 10px;
  }
  i {
    position: absolute;
    top: -4px;
    right: -12px;
    cursor: pointer;
  }
}
.container {
  background: #f2f4f8;
  @extend .minwid;
  .conts {
    padding: 40px 0;
    @extend .tutWid;
  }
  .kuai {
    box-sizing: border-box;
    padding: 20px;
    background: #fff;
  }
}
.list {
  &1 {
    .les {
      margin-right: 0 !important;
    }
    .ris {
      flex: 1;
      padding-left: 68px;
    }
  }
  &2 {
    .ris {
      padding-left: 82px;
    }
  }
  &3 {
    .ris {
      padding-left: 95px;
    }
  }
  display: flex;
  &:not(:nth-last-child(1)) {
    margin-bottom: 10px;
    border-bottom: 1px dashed #f0f0f0;
  }
  .les {
    margin-right: 20px;
    color: #292e41;
    font-size: 14px;
    font-weight: bold;
  }
  .contris {
    flex: 1;
  }
  .ris {
    display: flex;
    .a1 {
      color: rgb(118, 124, 147);
      font-size: 14px;
      font-weight: bold;
      margin-right: 20px;
    }
    .a2 {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      & > div {
        margin-bottom: 10px;
        margin-right: 20px;
        width: 125px;
        color: #606266;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
      }
    }
    /deep/ .el-checkbox-group {
      .el-checkbox {
        margin-bottom: 10px;
        margin-right: 15px;
        min-width: 110px;
      }
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: unset !important;
      }
    }
  }
}
.kuai-top {
  @extend .fsb;
  align-items: flex-end;
  padding-bottom: 20px;
  .les {
    @extend .fsb;
    align-items: flex-end;
    .a1 {
      font-size: 18px;
      font-weight: bold;
    }
    .a2 {
      padding-left: 20px;
      color: #a9a9a9;
      font-size: 14px;
    }
  }
  .ris {
    @extend .cflex;
    .a1 {
      width: 300px;
    }
    .b1 {
      margin-left: 15px;
      margin-right: 9px;
    }
    .b2 {
      margin-left: 0;
    }
  }
}
.kuai-mids {
  /deep/ .el-table__row{cursor: pointer;}
  /deep/ .el-table {
    border: 1px solid #dfe6ec;
    border-bottom: none;
    th > .cell {
      font-weight: normal;
    }
    thead {
      tr,
      th {
        background: #eef1f6 !important;
      }
    }
    tr,
    th,
    td,
    a {
      text-align: center !important;
      color: #1f2d3d;
    }
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #e6f6ff !important;
  }
  /deep/ .el-table__row:hover div,
  .el-table__row:hover a {
    color: #148ef5;
  }
  .tab-hot {
    margin-left: 10px;
    padding: 1px;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    font-size: 12px;
  }
}
.kuai-foots {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
