<template>
  <div class="container" style="display:none;">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" class="t">
        <template slot="title">
          <img src="../assets/img/pcimg31.png" alt="" class="a1" />
          <div class="a2">简历解析</div>
        </template>
        <div class="mids">可通过上传个人简历来解析内容创建个人资料</div>
        <el-upload
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          :before-upload="beforeAvatarUpload"
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleFileSuccess"
          v-model="upfiles"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            点击或将文件拖拽到这里上传<br />支持扩展名：.pdf,.docx,.doc，且不超过5M
          </div>
        </el-upload>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "cv1",
  data() {
    return {
      activeNames: ["1"],
      upfiles: "",
    };
  },
  methods: {
    beforeAvatarUpload(file) {
      console.log(file);
      const isDoc = file.type === "application/msword";
      const isDocx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      const isPdf = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isDoc && !isDocx && !isPdf) {
        this.$message.warning("只能上传.pdf,.docx,.doc文件！");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传文件大小不能超过 5MB!");
        return false;
      }
    },
    handleExceed() {
      this.$message.warning("当前限制上传1个文件，要替换的话，请先删除已上传的");
    },
    handleFileSuccess(response, file, fileList) {
      this.upfiles = fileList;
      this.$message.success("上传成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 100px;
    }
  }
}
.mids {
  margin-top: 10px;
  color: #969696;
  font-size: 14px;
  margin-bottom: 16px;
}
.container {
  /deep/ .el-collapse .el-collapse-item__content {
    margin-bottom: 25px;
  }
}
</style>
