<template>
  <div class="container">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" class="t">
        <template slot="title">
          <img src="../assets/img/pcimg31.png" alt="" class="a1" />
          <div class="a2">求职意向</div>
        </template>
        <div class="cts">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="ruleForm"
          >
            <div class="ls3">
              <el-form-item label="应聘岗位" prop="post">
                <el-input
                  v-model="ruleForm.post"
                  placeholder="请输入应聘岗位"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="期望月薪" prop="salary">
                <el-select
                  v-model="ruleForm.salary"
                  placeholder="请选择月薪范围"
                  clearable
                  ref="yuexin"
                >
                  <el-option
                    v-for="item in salaryOptions"
                    :key="item"
                    :value="item"
                  >
                  </el-option>

                  <div class="addcss0922-1">
                    <el-input
                      placeholder="请输入月薪范围"
                      v-model="yuexin1"
                      size="mini"
                    >
                    </el-input>
                    <div>-</div>
                    <el-input
                      placeholder="请输入月薪范围"
                      v-model="yuexin2"
                      size="mini"
                    >
                    </el-input>
                    <el-button type="primary" size="mini" @click="yuexin"
                      >确认</el-button
                    >
                    <el-button size="mini" @click="qxyuexin">取消</el-button>
                  </div>
                </el-select>
              </el-form-item>
            </div>
            <div class="ls3">
              <el-form-item prop="options" label="期望工作地区" class="ls2">
                <el-cascader
                  :options="options"
                  v-model="ruleForm.options"
                  placeholder="省/市/区/(县)"
                  clearable
                >
                  <template slot-scope="{ data }">
                    <span>{{ data.value }}</span>
                  </template>
                </el-cascader>
              </el-form-item>
              <el-form-item label="期望从事职业" prop="zhiye">
                <el-input
                  v-model="ruleForm.zhiye"
                  placeholder="请输入期望从事职业"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
            <div class="ls3">
              <el-form-item label="期望从事行业" prop="hangye">
                <el-input
                  v-model="ruleForm.hangye"
                  placeholder="请输入期望从事行业"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="目前状况" prop="zhuagnkuang">
                <el-select
                  v-model="ruleForm.zhuagnkuang"
                  placeholder="请选择目前状况"
                  clearable
                >
                  <el-option
                    v-for="item in zhuagnkuangOptions"
                    :key="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="ls3">
              <el-form-item prop="data" label="到岗时间" class="ls2">
                <el-date-picker
                  v-model="ruleForm.data"
                  type="date"
                  placeholder="请选择到岗时间"
                  clearable
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="工作性质" prop="xinzhi">
                <el-select
                  v-model="ruleForm.xinzhi"
                  placeholder="请选择工作性质"
                  clearable
                >
                  <el-option
                    v-for="item in xinzhiOptions"
                    :key="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item style="text-align: right">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import cityData from "../comjs/citydata";
export default {
  name: "cv3",
  props: {
    qiuzhi: Object,
  },
  data() {
    return {
      activeNames: ["1"],
      options: cityData,
      salaryOptions: ["3000~5000", "5000~8000", "8000以上"],
      zhuagnkuangOptions: ["在职", "离职"],
      xinzhiOptions: ["工作性质1", "工作性质2"],
      ruleForm: {
        post: "",
        salary: "",
        options: "",
        zhiye: "",
        hangye: "",
        data: "",
        xinzhi: "",
      },
      rules: {
        post: [
          {
            required: true,
            message: "岗位不能为空",
            trigger: "blur",
          },
        ],
      },
      yuexin1: "",
      yuexin2: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.ruleForm.post = this.qiuzhi.position;
      this.ruleForm.salary = this.qiuzhi.wages;
      this.ruleForm.options = [
        this.qiuzhi.province,
        this.qiuzhi.city,
        this.qiuzhi.area,
      ];
      this.ruleForm.zhiye = this.qiuzhi.occupation;
      this.ruleForm.hangye = this.qiuzhi.industry;
      this.ruleForm.zhuagnkuang = this.qiuzhi.situation;
      this.ruleForm.data = this.qiuzhi.arrivalTime;
      this.ruleForm.xinzhi = this.qiuzhi.nature;
    }, 1000);
  },
  methods: {
    yuexin() {
      if (this.yuexin1 == "" || this.yuexin2 == "") {
        this.$message.error('请输入月薪范围')
      } else {
        this.ruleForm.salary = this.yuexin1 + "~" + this.yuexin2;
        this.$refs.yuexin.blur();
      }
    },
    qxyuexin(){
      this.yuexin1 = ""
      this.yuexin2 = ""
      this.ruleForm.salary = ""
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/Api/user/saveIntention", {
              token: localStorage.getItem("token"),
              position: this.ruleForm.post,
              wages: this.ruleForm.salary,
              province: this.ruleForm.options[0],
              city: this.ruleForm.options[1],
              area: this.ruleForm.options[2],
              occupation: this.ruleForm.zhiye,
              industry: this.ruleForm.hangye,
              situation: this.ruleForm.zhuagnkuang,
              arrivalTime: this.ruleForm.data,
              nature: this.ruleForm.xinzhi,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message.success(res.data.message);
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
        console.log(valid);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.addcss0922-1 {
  padding: 0 20px;
  line-height: 35px;
  display: flex;
  .el-input {
    margin: 0 8px;
    width: 138px;
  }
  .el-button {
    height: 28px;
    margin-top: 3px;
  }
}
.container {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 25px;
    }
  }
}

.cts {
  margin-top: 50px;
  .ls1 {
    @extend .fsb;
    align-items: flex-start;
    margin-bottom: 20px;
    .les {
      width: 290px;
    }
    .ris {
      width: 270px;
      .fonts {
        font-size: 13px;
        color: #969696;
      }
    }
    .a1 {
      /deep/ .el-form-item__label {
        text-align: left;
        float: unset;
      }
      /deep/ .el-upload-list__item-thumbnail {
        object-fit: scale-down;
      }
      /deep/ .el-form-item__content {
        .el-upload--picture-card {
          width: 118px;
        }
        & > div:nth-of-type(1) {
          display: flex;
        }
      }
    }
  }
  .ls2 {
    display: block;
    /deep/ .el-cascader {
      width: 100%;
    }
    /deep/ .el-form-item__label {
      display: block;
      float: unset;
      text-align-last: left;
    }
    /deep/ .el-date-editor.el-input {
      width: 100%;
    }
  }
  .ls3 {
    @extend .fsb;
    /deep/ .el-form-item {
      width: 290px;
    }
    /deep/ .el-select {
      width: 100%;
    }
  }
}
</style>
