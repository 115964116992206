<template>
  <div class="container">
    <div class="conts">
      <div>
        <p>1.知识产权保护</p>
        该软件(广东豪美新材股份有限公司)的著作权和所有权由广东豪美新材股份有限公司所有。该软件受广东豪美新材股份有限公司国著作权法和国际条约条款的保护。商标的使用应符合商标惯例，包括和商标所有人名称的一致。商标可以仅用于对该软件产品的识别。对于任何商标的该种使用都不会导致对该商标使用权的拥有。除上述以外，该协议不授予你该软件上的任何知识产权。
        <p>2.软件的使用</p>
        你可以： <br/><br />
        (1)根据该协议条款使用该软件;<br/><br />
        (2)复制和备份;<br/><br />
        (3)不对文档作任何增加或修改以文档下载的最初形式将此软件拷贝给他人;<br/><br />
        (4)在网址上通过email或者其他任何物理媒体分发该软件。 <br/><br />你不可以：<br/><br />
        (1)对本系统进行逆向工程、反汇编、解体拆卸或任何试图发现该软件工作程序获源代码的行为;<br/><br />
        (2)未经广东豪美新材股份有限公司的书面许可出售租赁该程序;<br/><br />
        (3)创造派生性产品包括含有该系统的更大的系统或另外的程序和包裹;<br/><br />
        (4)将该软件和与广东豪美新材股份有限公司没有分离授权协议的产品或山版物捆绑销售;<br/><br />
        (5)使用和拷贝没有广东豪美新材股份有限公司授权的包含在广东豪美新材股份有限公司之中的icons，1ogosorartwork;<br/><br />
        (6)使用该软件改进其他产品和服务;<br/><br />
        (7)任何反相工程、反相编译和反汇编以及其他任何试图发现由该内程旗帜广告和该软件中网络共享功能所使用的协议的行为;<br/><br />
        (8)在任何图像中使用该软件在任何情况下以任何方式对任何个人和商业实体的诋毁行为;或暗示由软件、软件供应商、软件许可商或其他在该软件中描绘的实体任何形式的认可;<br/><br />
        (9)因软件拷贝控诉媒体和运输超过了合理成本。 
        <p>3.条款</p>
        只要你使用该软件，该授权协议就持续有效，除非由于你不遵守该协议的条款而被终止使用权利。一旦协议终止，你必须同意毁掉你所有的该软件的所有拷贝。
        <p>4.有限的保证</p>
        广东豪美新材股份有限公司不保证也不能保证你由于使用该软件或其他相关项目因此可以得到的结果的履行。公司对于第三方、商业行为能力和任何特殊的目的的非侵害和适当性不承担任何保证，包括明示和暗示的。没有广东豪美新材股份有限公司给予的口头或者书面通知或者建议，他的分销商，代理商或者雇员将给予指证广东豪美新材股份有限公司不对安全担保，也不对数据的丢失和由于在互联网上使用该软件带来的任何风险担保。美国有些州禁止排除暗示担保的限制，所以上述例外或限制也许不适用于你。<br/>
        <p>5.有限责任</p>
        广东豪美新材股份有限公司的公司不对任何情况下带来的损失承担责任，包括使用该软件、不能使用该软件和书面材料带来的利润损失，或其他附随性的损失和结果性损失，以及即使是广东豪美新材股份有限公司建议或推荐所带来的可能性损失。广东豪美新材股份有限公司也不对由于黑客的进入而导致的数据库损失负责。有些州不允许例外、不允许对附随性损害、结果性损害和特别损害进行限制，所以上述限制也许并不适用于你。<br/>
        <p>6.附则</p>
        该协议适用于加利福尼亚州的法律，如果该协议的任何条款违法、无效，或者因为某种原因导致不能执行，那么该条款被认为与该协议分离将不会影响该协议其他条款的效力和执行力。该协议是一个完整的陈述和说明。在与该协议主题相关的事情上，具有优先使用性。如果对该协议有任何疑问，你可以用下列任何一个地址和广东豪美新材股份有限公司联系。<br/>
        <p>7.互联网和隐私</p>
        该程序和互联网连接，因此个人隐私的各个方面会受到影响。你所访问的网络也许就跟踪你的访问。由于互联网的国际性本质，来自这些风险的合法保护将取决于你所居住的地方合法救济也会受到限制。当这些风险对于许多网络程序都很普通时，如果你很在乎这些问题，那就不要使用该软件。<br/><br/>
        有限公司联系方式：86-763-3699777 。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Serve",
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f4f8;
  @extend .minwid;
  overflow: hidden;
}
.conts {
  @extend .tutWid;
  background: #fff;
  height: calc(100vh - 260px);
  box-sizing: border-box;
  padding: 16px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
  @extend .scrollbar;
  p{font-weight: bold;font-size: 18px;}
}
</style>
