<template>
  <div>
    <div class="foots">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1" class="t">
          <template slot="title">
            <img src="../assets/img/pcimg31.png" alt="" class="a1" />
            <div class="a2">基本信息</div>
          </template>
          <div class="cts">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <div class="ls1">
                <div class="les">
                  <el-form-item label="姓名" prop="name">
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="性别" prop="gender" class="a1">
                    <el-radio-group v-model="ruleForm.gender">
                      <el-radio label="男" border></el-radio>
                      <el-radio label="女" border></el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div class="ris">
                  <el-form-item label="个人照片" class="a1" prop="upimg">
                    <el-upload
                      action=""
                      class="avatar-uploader"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      :http-request="uploadImgList"
                      :on-exceed="handleExceed"
                      v-model="ruleForm.upimg"
                    >
                      <img
                        width="100%"
                        v-if="dialogImageUrl"
                        :src="dialogImageUrl"
                        alt=""
                      />
                      <i v-else class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                  <div class="fonts">照片格式：jpg/png，建议使用标准2寸照。</div>
                </div>
              </div>
              <el-form-item label="籍贯" prop="hometown">
                <el-input
                  v-model="ruleForm.hometown"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              
              <el-form-item label="证件号码" prop="idcard">
                <el-input
                  v-model="ruleForm.idcard"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="data" label="出生日期" class="ls2">
                <el-date-picker
                  v-model="ruleForm.data"
                  type="date"
                  placeholder="请选择"
                  clearable
                ></el-date-picker>
              </el-form-item>
              <div class="ls3">
                <el-form-item label="手机号码" prop="phone">
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="ruleForm.email"
                    placeholder="请输入"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="ls3">
                <el-form-item label="身高(cm)" prop="height">
                  <el-input
                    v-model="ruleForm.height"
                    placeholder="请填写身高(cm)"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="政治面貌" prop="politics">
                  <el-select
                    v-model="ruleForm.politics"
                    placeholder="请选择政治面貌"
                    clearable
                  >
                    <el-option v-for="item in politicsOptions" :key="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="ls3">
                <el-form-item label="婚姻状况" prop="marriage">
                  <el-select
                    v-model="ruleForm.marriage"
                    placeholder="请选择婚姻状况"
                    clearable
                  >
                    <el-option v-for="item in marriageOptions" :key="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="兴趣爱好" prop="interest">
                  <el-input
                    v-model="ruleForm.interest"
                    placeholder="请填写兴趣爱好"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item label="微信号" prop="wxcode">
                <el-input
                  v-model="ruleForm.wxcode"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="valuesAccount" label="户口所在地" class="ls2">
                <el-cascader
                  :options="optionsAccount"
                  v-model="ruleForm.valuesAccount"
                  placeholder="省/市/区/(县)"
                  clearable
                >
                  <template slot-scope="{ data }">
                    <span>{{ data.value }}</span>
                  </template>
                </el-cascader>
              </el-form-item>
              <el-form-item prop="valuesLive" label="现居住地" class="ls2">
                <el-cascader
                  :options="optionsLive"
                  v-model="ruleForm.valuesLive"
                  placeholder="省/市/区/(县)"
                  clearable
                >
                  <template slot-scope="{ data }">
                    <span>{{ data.value }}</span>
                  </template>
                </el-cascader>
              </el-form-item>
              <div class="ls3">
                <el-form-item label="学历" prop="edu">
                  <el-select v-model="ruleForm.edu" placeholder="请选择学历" clearable>
                    <el-option v-for="item in eduOptions" :key="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="工作经验" prop="exp">
                  <el-select
                    v-model="ruleForm.exp"
                    placeholder="请选择工作经验"
                    clearable
                  >
                    <el-option v-for="item in expOptions" :key="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item style="text-align: right">
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import cityData from "../comjs/citydata";
import { Host } from "../comjs/com";
export default {
  name: "cv2",
  props: {
    jibenxinxi: Object,
  },
  data() {
    let validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空!"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      }
      callback();
    };
    return {
      activeNames: ["1"],
      dialogImageUrl: "",
      dialogVisible: false,
      politicsOptions: [
        "中共党员",
        "中共预备党员",
        "共青团员",
        "民革党员",
        "民盟盟员",
        "民建会员",
        "民进会员",
        "农工党党员",
        "致公党党员",
        "九三学社社员",
        "台盟盟员",
        "无党派人士",
        "群众",
      ],
      marriageOptions: ["已婚", "未婚"],
      eduOptions: [
        "不限",
        "统招",
        "职业高中",
        "大专",
        "大学本科",
        "研究生",
        "博士",
        "博士后",
      ],
      expOptions: ["1年以内", "1~3年", "3~5年", "5年以上"],
      optionsAccount: cityData,
      optionsLive: cityData,
      ruleForm: {
        name: "",
        gender: "",
        upimg: "",
        hometown: "",
        idcard: "",
        data: "",
        phone: "",
        email: "",
        height: "",
        politics: "",
        marriage: "",
        interest: "",
        valuesAccount: "",
        valuesLive: "",
        edu: "",
        exp: "",
        wxcode:'',
      },
      rules: {
        name: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur",
          },
        ],
        wxcode: [
          {
            required: true,
            message: "微信号不能为空",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatorPhone,
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        gender: [{ required: true, message: "请选择您的性别", trigger: "change" }],
        hometown: [
          {
            required: true,
            message: "籍贯不能为空",
            trigger: "blur",
          },
        ],
        // idcard: [
        //   {
        //     required: true,
        //     message: "证件号码不能为空",
        //     trigger: "blur",
        //   },
        // ],
        data: [
          {
            required: true,
            message: "出生日期不能为空",
            trigger: "blur",
          },
        ],
        height: [
          {
            required: true,
            message: "身高不能为空",
            trigger: "blur",
          },
        ],
        // politics: [
        //   {
        //     required: true,
        //     message: "请选择政治面貌",
        //     trigger: "change",
        //   },
        // ],
        // marriage: [
        //   {
        //     required: true,
        //     message: "请选择婚姻状况",
        //     trigger: "change",
        //   },
        // ],
        // valuesAccount: [
        //   {
        //     required: true,
        //     message: "请选择户籍所在地",
        //     trigger: "change",
        //   },
        // ],
        valuesLive: [
          {
            required: true,
            message: "请选择现居住地",
            trigger: "change",
          },
        ],
        edu: [
          {
            required: true,
            message: "请选择学历",
            trigger: "change",
          },
        ],
        exp: [
          {
            required: true,
            message: "请选择工作经验",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.ruleForm.name = this.jibenxinxi.realname;
      let gender;
      if (this.jibenxinxi.gender == 1) {
        gender = "男";
      } else {
        gender = "女";
      }
      this.ruleForm.gender = gender;
      this.dialogImageUrl = this.jibenxinxi.avatarUrl;
      this.ruleForm.hometown = this.jibenxinxi.native_place;
      this.ruleForm.idcard = this.jibenxinxi.identification;
      this.ruleForm.data = this.jibenxinxi.birthday;
      this.ruleForm.phone = this.jibenxinxi.phone;
      this.ruleForm.email = this.jibenxinxi.email;
      this.ruleForm.height = this.jibenxinxi.high;
      this.ruleForm.politics = this.jibenxinxi.political;
      this.ruleForm.marriage = this.marriageOptions[this.jibenxinxi.marriage - 1];
      this.ruleForm.interest = this.jibenxinxi.hobby;
      this.ruleForm.wxcode = this.jibenxinxi.wxcode;
      this.ruleForm.valuesAccount = [
        this.jibenxinxi.regProvince,
        this.jibenxinxi.regCity,
        this.jibenxinxi.regArea,
      ];
      this.ruleForm.valuesLive = [
        this.jibenxinxi.province,
        this.jibenxinxi.city,
        this.jibenxinxi.area,
      ];
      this.ruleForm.edu = this.jibenxinxi.education;
      this.ruleForm.exp = this.jibenxinxi.experience;
    }, 1000);
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // var imgs = res.data.filename;
      console.log(res, file);
      // if (imgs) {
      //   this.ruleForm.upimg = res.data.filename;
      // }
    },
    uploadImgList(params) {
      const file = params.file,
        form = new FormData();
      form.append("file", file);
      form.append("token", localStorage.getItem("token"));
      this.$axios.post("/Api/upload/index", form).then((res) => {
        this.dialogImageUrl = Host + res.data.data.file;
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPng) {
        this.$message.warning("只能上传图片！");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传头像图片大小不能超过 2MB!");
        return false;
      }
    },
    handleExceed() {
      this.$message.warning("当前限制上传 1 张图片，要替换的话，请先删除已上传的");
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let addmar;
          if (this.ruleForm.marriage == "保密") {
            addmar = 0;
          } else if (this.ruleForm.marriage == "已婚") {
            addmar = 1;
          } else if (this.ruleForm.marriage == "未婚") {
            addmar = 2;
          }
          this.$axios
            .post("/Api/user/edit", {
              token: localStorage.getItem("token"),
              realname: this.ruleForm.name,
              gender: this.ruleForm.gender,
              avatarUrl: this.dialogImageUrl,
              native_place: this.ruleForm.hometown,
              identification: this.ruleForm.idcard,
              birthday: this.ruleForm.data,
              phone: this.ruleForm.phone,
              email: this.ruleForm.email,
              high: this.ruleForm.height,
              political: this.ruleForm.politics,
              marriage: addmar,
              hobby: this.ruleForm.interest,
              province: this.ruleForm.valuesLive[0],
              city: this.ruleForm.valuesLive[1],
              area: this.ruleForm.valuesLive[2],
              regProvince: this.ruleForm.valuesAccount[0],
              regCity: this.ruleForm.valuesAccount[1],
              regArea: this.ruleForm.valuesAccount[2],
              education: this.ruleForm.edu,
              experience: this.ruleForm.exp,
              wxcode:this.ruleForm.wxcode,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: res.data.message,
                  duration: 2000,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
        console.log(valid);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.foots {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 100px;
    }
  }
  /deep/ .el-collapse .el-collapse-item__content {
    margin-bottom: 25px;
  }
}
.cts {
  margin-top: 50px;
  .ls1 {
    @extend .fsb;
    align-items: flex-start;
    margin-bottom: 20px;
    .les {
      width: 290px;
    }
    .ris {
      width: 270px;
      .fonts {
        font-size: 13px;
        color: #969696;
      }
    }
    .a1 {
      /deep/ .el-form-item__label {
        text-align: left;
        float: unset;
      }
      /deep/ .el-upload-list__item-thumbnail {
        object-fit: scale-down;
      }
      /deep/ .el-form-item__content {
        .el-upload--picture-card {
          width: 118px;
          display: flex;
          align-items: center;
        }
        & > div:nth-of-type(1) {
          display: flex;
        }
      }
    }
  }
  .ls2 {
    display: block;
    /deep/ .el-cascader {
      width: 100%;
    }
    /deep/ .el-form-item__label {
      display: block;
      float: unset;
      text-align-last: left;
    }
    /deep/ .el-date-editor.el-input {
      width: 100%;
    }
  }
  .ls3 {
    @extend .fsb;
    /deep/ .el-form-item {
      width: 290px;
    }
    /deep/ .el-select {
      width: 100%;
    }
  }
}
.avatar-uploader {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  /deep/ .el-upload--text {
    @extend .cflex;
    width: 100%;
  }
}
.avatar-uploader img {
  @extend .imgcontain;
}
.avatar-uploader i {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
