<template>
  <div id="app" ref="gundong">
    <Tops :isTops="isTops" v-if="isHide"></Tops>
    <router-view />
    <Foots v-if="isHide"></Foots>
  </div>
</template>

<script>
import Tops from "./components/Tops.vue";
import Foots from "./components/Foots.vue";
export default {
  name: "App",
  components: {
    Tops,
    Foots,
  },
  data() {
    return {
      isTops: true,
      isHide: true,
    };
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", this.handleScrollx, true);
    if (that.$route.name == "notfount") {
      that.isHide = false;
      return false;
    } else {
      that.isHide = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollx);
  },
  methods: {
    handleScrollx() {
      let that = this;
      if (this.$refs.gundong.getBoundingClientRect().top < 0) {
        that.isTops = false;
      } else {
        that.isTops = true;
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.name == "index") {
        this.isHide = true;
      }
    },
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: dinlight;
  src: url(./assets/font/DINPRO-LIGHT.TTF);
}
@font-face {
  font-family: dinblack;
  src: url(./assets/font/DINPRO-BLACK.OTF);
}
@font-face {
  font-family: dinbold;
  src: url(./assets/font/DINPRO-BOLD.TTF);
}
@font-face {
  font-family: dinmedium;
  src: url(./assets/font/DINPRO-MEDIUM.TTF);
}
@font-face {
  font-family: dinreg;
  src: url(./assets/font/DINPRO-REGULAR.OTF);
}
.dinlight {
  font-family: dinlight;
}
.dinblack {
  font-family: dinblack;
}
.dinbold {
  font-family: dinbold;
}
.dinmedium {
  font-family: dinmedium;
}
.dinreg {
  font-family: dinreg;
}
a {
  text-decoration: none;
}
.com1 {
  @extend .fsb;
  flex-direction: column;
  .a1 {
    margin-bottom: 20px;
  }
  .a2 {
    color: #646464;
    font-size: 20px;
  }
}
.com2 {
  width: 160px;
  height: 50px;
  @extend .cflex;
  @extend .bg;
  color: #fff;
  border-radius: 30px;
  font-size: 16px;
}
.el-cascader-menu {
  max-height: 300px;
}
</style>
