<template>
  <div class="container">
    <div class="conts">
      <div class="les">
        <div class="tops">{{ datas.title }}</div>
        <div class="mids">
          <div class="a1" style="margin-bottom:14px;">
            <div class="b1">
              <div class="c1">工作地点：</div>
              <div class="c2">{{ datas.province }}-{{ datas.city }}</div>
            </div>
            <div class="b1">
              <div class="c1">事业部：</div>
              <div class="c2">{{ datas.department }}</div>
            </div>
            <div class="b1">
              <div class="c1">发布日期：</div>
              <div class="c2">{{ datas.releaseDate }}</div>
            </div>
          </div>
          <div class="a1">
            <div class="b1">
              <div class="c1">薪资：</div>
              <div class="c2">{{ datas.wage }}</div>
            </div>
            <div class="b1">
              <div class="c1">工作年限：</div>
              <div class="c2">{{ datas.workingyears }}</div>
            </div>
            <div class="b1">
              <div class="c1">学历：</div>
              <div class="c2">{{ datas.education }}</div>
            </div>
          </div>
          <div class="a2">
            <div class="b1">岗位职责</div>
            <div class="b2" v-html="datas.responsibilities"></div>
          </div>
          <div class="a2 a3">
            <div class="b1">任职资格</div>
            <div class="b2" v-html="datas.qualifications"></div>
          </div>
          <div class="a2 a3">
            <div class="b2">豪美新材股份有限公司创建于1991年，股票代码：002988 SZ；欢迎加入豪美新材大家庭。</div>
          </div>
        </div>
        <div class="foots">
          <el-button type="primary" @click="getPost">申请岗位</el-button>
          <div class="a1">
            <i class="el-icon-share b1">
              <div class="c1">
                <div ref="qrcode" id="qrcode"></div>
              </div>
            </i>
            <i class="el-icon-star-off b2" @click="collect" v-if="isShou == 0"></i>
            <i class="el-icon-star-on b2 b3" v-else @click="collect"></i>
          </div>
        </div>
      </div>
      <Content_Left :geturl="geturl" :cateid="2"></Content_Left>
    </div>
  </div>
</template>

<script>
import { Application, Collect, phoneHost } from "../comjs/com";
import QRCode from "qrcodejs2";
import Content_Left from "../components/Content_Left.vue";
export default {
  name: "SocietyContent",
  components: { QRCode, Content_Left },
  data() {
    return {
      url: "",
      geturl: "/society",
      isShou: '',
      datas: {},
    };
  },
  watch: {
    "$route.query.id"(val) {
      this.getcontent(val);
      this.url = phoneHost + this.geturl + "-content?id=" + val;
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
      this.$nextTick(() => {
        new QRCode("qrcode", {
          width: 100,
          height: 100,
          text: this.url,
        });
      });
    },
  },
  mounted() {
    this.url = phoneHost + this.geturl + "-content?id=" + this.$route.query.id;
    let that = this;
    let getid = this.$route.query.id;
    if (getid) {
      this.getcontent(getid);
    } else {
      this.$router.push("/");
    }
    this.$nextTick(() => {
      new QRCode("qrcode", {
        width: 100,
        height: 100,
        text: that.url,
      });
    });
  },
  methods: {
    getPost() {
      Application();
      let islogs = localStorage.getItem("Logins");
      if (islogs) {
        this.$axios
          .post("/Api//post/applyPosts", {
            id: this.$route.query.id,
            token: localStorage.getItem("token"),
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.$message.success(res.data.message);
            } else if (res.data.code == 2) {
              this.$message({
                message: res.data.message,
                duration: 1500,
              });
              setTimeout(() => {
                this.$router.push("/cv");
              }, 1700);
            } else {
              this.$message({
                message: res.data.message,
              });
            }
          });
      }
    },
    collect() {
      Collect();
      let islogs = localStorage.getItem("Logins");
      if (islogs) {
        if (this.isShou == 1) {
          this.isShou = 0;
        } else {
          this.isShou = 1;
        }
        this.$axios
          .post("/Api/post/collection", {
            id: this.$route.query.id,
            token: localStorage.getItem("token"),
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.$message.success(res.data.message);
            } else {
              this.$message({
                message: res.data.message,
              });
            }
          });
      }
    },
    getcontent(e) {
      this.$axios.post("/Api/post/get", { id: e,token:localStorage.getItem('token') }).then((res) => {
        if (res.data.code == 1) {
          this.datas = res.data.data;
          this.isShou = res.data.data.iscollection
        } else {
          this.$message({
            message: res.data.message,
            duration: 1000,
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 1100);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f4f8;
  padding: 40px 0;
  @extend .minwid;
  .conts {
    @extend .tutWid;
    @extend .fsb;
    align-items: flex-start;
  }
}
.les {
  min-height: calc(100vh - 260px);
  background: #fff;
  box-sizing: border-box;
  width: 846px;
  margin-right: 20px;
  .tops {
    height: 74px;
    background: #fafafa;
    color: #000;
    font-weight: 600;
    line-height: 74px;
    font-size: 22px;
    padding: 0 20px;
    border-bottom: 2px solid #f2f2f2;
  }
  .mids {
    box-sizing: border-box;
    padding: 25px;
    .a1 {
      @extend .cflex;
      margin-bottom: 28px;
      .b1 {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        font-size: 14px;
        .c1 {
          color: #292e41;
          margin-right: 10px;
          font-weight: 600;
        }
        .c2 {
          color: #767c93;
        }
      }
    }
    .a2 {
      .b1 {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        font-weight: 700;
        color: #292e41;
      }
      .b2 {
        color: #767c93 !important;
        p {
          font-size: 14px;
          min-height: 30px;
          line-height: 30px;
          word-break: break-all;
          margin: 0;
        }
      }
    }
    .a3 {
      margin-top: 20px;
    }
  }
  .foots {
    display: flex;
    padding: 30px 20px 130px;
    box-sizing: border-box;
    /deep/ .el-button {
      width: 120px;
      background: #148ef5;
      border-radius: 2px;
      height: 40px;
    }
    .a1 {
      border-left: 1px solid #dcdee4;
      margin-left: 20px;
      padding-top: 3px;
      height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      .b1 {
        margin: 3px 0 0 20px;
        font-size: 16px;
        background: rgb(32, 160, 255);
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        padding: 4px;
        position: relative;
        &:hover .c1 {
          display: block;
        }
        .c1 {
          position: absolute;
          left: 10px;
          padding: 10px;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
          top: 100%;
          display: none;
        }
      }
      .b2 {
        font-size: 28px;
        margin-left: 20px;
        cursor: pointer;
        margin-top: 1px;
      }
      .b3 {
        @extend .color;
        font-size: 34px !important;
        margin-top: -2px;
        margin-left: 18px;
      }
    }
  }
}
/deep/ .el-message-box,
.el-popup-parent--hidden {
  overflow: auto !important;
}
</style>
