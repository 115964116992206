<template>
  <div class="container">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" class="t">
        <template slot="title">
          <img src="../assets/img/pcimg31.png" alt="" class="a1" />
          <div class="a2">项目经验</div>
        </template>
        <div class="ctx">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" class="ruleForm">
            <div v-for="(item, index) in ruleForm.getData" :key="item + index">
              <div
                v-if="!zhankai && showNum == index"
                style="background: #fcfcfd; border: 1px solid #f1f2f4; padding: 20px"
              >
                <el-form-item
                  label="工作时间 "
                  :prop="'getData.' + index + '.projectTime'"
                  :rules="rules.projectTime"
                  class="addls1"
                >
                  <!-- <el-date-picker
                    v-model="item.projectTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker> -->
                  <div style="display: flex">
                    <el-date-picker
                      v-model="item.projectTime[0]"
                      type="date"
                      placeholder="开始日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <div style="margin:0 30px;">至</div>
                    <el-date-picker
                      v-model="item.projectTime[1]"
                      type="date"
                      placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item
                  label="公司名称"
                  :prop="'getData.' + index + '.company'"
                  :rules="rules.company"
                >
                  <el-input
                    v-model="item.company"
                    placeholder="请输入公司名称"
                    clearable
                  ></el-input>
                </el-form-item>
                <div class="ls3">
                  <el-form-item
                    label="担任职位 "
                    :prop="'getData.' + index + '.position'"
                    :rules="rules.position"
                  >
                    <el-input
                      v-model="item.position"
                      placeholder="请输入担任职位"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="公司规模"
                    :prop="'getData.' + index + '.size'"
                    :rules="rules.size"
                  >
                    <el-select v-model="item.size" placeholder="请选择公司规模" clearable>
                      <el-option v-for="aitem in eduOptions" :key="aitem" :value="aitem">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="ls3">
                  <el-form-item
                    label="行业类别"
                    :prop="'getData.' + index + '.industry'"
                    :rules="rules.industry"
                  >
                    <el-input
                      v-model="item.industry"
                      placeholder="请输入行业类别"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="企业性质"
                    :prop="'getData.' + index + '.nature'"
                    :rules="rules.nature"
                  >
                    <el-select
                      v-model="item.nature"
                      placeholder="请选择企业性质"
                      clearable
                    >
                      <el-option
                        v-for="aitem in natureOptions"
                        :key="aitem"
                        :value="aitem"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="工作内容" :prop="'getData.' + index + '.content'">
                  <el-input
                    v-model="item.content"
                    placeholder="请输入工作内容"
                    clearable
                    type="textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <div>
                  <el-button type="primary" @click="submitForm('ruleForm1')"
                    >确认</el-button
                  >
                  <el-button @click="reset">取消</el-button>
                </div>
              </div>

              <div v-if="zhankai || showNum !== index" class="zhanshi">
                <div class="posi">
                  <i class="el-icon-edit pa1" @click="edit(index)"></i>
                  <i class="el-icon-delete pa1 pa2" @click="del(index)"></i>
                </div>
                <div class="a1">
                  <div class="b1">工作时间</div>
                  <div class="b2">
                    {{ item.projectTime[0] }} ~ {{ item.projectTime[1] }}
                  </div>
                </div>
                <div class="a1">
                  <div class="b1">公司名称</div>
                  <div class="b2">{{ item.company }}</div>
                </div>
                <div class="a1">
                  <div class="b1">公司规模</div>
                  <div class="b2">{{ item.position }}</div>
                </div>
                <div class="a1">
                  <div class="b1">担任职位</div>
                  <div class="b2">{{ item.size }}</div>
                </div>
                <div class="a1">
                  <div class="b1">行业类别</div>
                  <div class="b2">{{ item.industry }}</div>
                </div>
                <div class="a1">
                  <div class="b1">企业性质</div>
                  <div class="b2">{{ item.nature }}</div>
                </div>
                <div class="a1">
                  <div class="b1">工作内容</div>
                  <div class="b2">{{ item.content }}</div>
                </div>
              </div>
            </div>
          </el-form>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm2" class="ruleForm">
            <div v-for="(item, index) in ruleForm.formData" :key="index">
              <el-form-item
                label="工作时间 "
                :prop="'formData.' + index + '.projectTime'"
                :rules="rules.projectTime"
                class="addls1"
              >
                <!-- <el-date-picker
                  v-model="item.projectTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker> -->
                <div style="display: flex">
                  <el-date-picker
                    v-model="item.startTime"
                    type="date"
                    placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <div style="margin:0 30px;">至</div>
                  <el-date-picker
                    v-model="item.endTime"
                    type="date"
                    placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item
                label="公司名称"
                :prop="'formData.' + index + '.company'"
                :rules="rules.company"
              >
                <el-input
                  v-model="item.company"
                  placeholder="请输入公司名称"
                  clearable
                ></el-input>
              </el-form-item>
              <div class="ls3">
                <el-form-item
                  label="担任职位 "
                  :prop="'formData.' + index + '.size'"
                  :rules="rules.size"
                >
                  <el-input
                    v-model="item.size"
                    placeholder="请输入担任职位"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="公司规模"
                  :prop="'formData.' + index + '.position'"
                  :rules="rules.position"
                >
                  <el-select
                    v-model="item.position"
                    placeholder="请选择公司规模"
                    clearable
                  >
                    <el-option v-for="aitem in eduOptions" :key="aitem" :value="aitem">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="ls3">
                <el-form-item
                  label="行业类别"
                  :prop="'formData.' + index + '.industry'"
                  :rules="rules.industry"
                >
                  <el-input
                    v-model="item.industry"
                    placeholder="请输入行业类别"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="企业性质"
                  :prop="'formData.' + index + '.nature'"
                  :rules="rules.nature"
                >
                  <el-select v-model="item.nature" placeholder="请选择企业性质" clearable>
                    <el-option v-for="aitem in natureOptions" :key="aitem" :value="aitem">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item label="工作内容" :prop="'formData.' + index + '.content'">
                <el-input
                  v-model="item.content"
                  placeholder="请输入工作内容"
                  clearable
                  type="textarea"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div style="text-align: right">
                <el-button type="primary" @click="submitForm1('ruleForm2')"
                  >保存并新增</el-button
                >
                <el-button @click="reset1">取消</el-button>
              </div>
            </div>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "cv8",
  props: {
    xiangmu: Array,
  },
  data() {
    return {
      activeNames: ["1"],
      showNum: 0,
      zhankai: true, //true就只是展示，false就是能编辑的
      eduOptions: ["100人以下", "100-500人", "500-1000人", "1000人以上"],
      natureOptions: [
        "国有企业",
        "集体企业",
        "私营企业",
        " 个体工商户",
        "合伙企业",
        "联营企业",
        "股份合作制企业",
        "有限责任公司",
        "股份有限公司",
      ],
      ruleForm: {
        formData: [
          {
            projectTime: "",
            company: "",
            position: "",
            size: "",
            content: "",
            industry: "",
            nature: "",
            startTime:'',
            endTime:'',
          },
        ],
        getData: [],
      },
      rules: {
        // projectTime: [
        //   {
        //     required: true,
        //     message: "工作时间不能为空",
        //     trigger: "blur",
        //   },
        // ],
        // company: [
        //   {
        //     required: true,
        //     message: "公司名称不能为空",
        //     trigger: "blur",
        //   },
        // ],
        // size: [
        //   {
        //     required: true,
        //     message: "担任职位不能为空",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  mounted() {
    // this.xiangmu
    setTimeout(() => {
      for (let i = 0; i < this.xiangmu.length; i++) {
        this.ruleForm.getData.push({
          id: this.xiangmu[i].id,
          projectTime: this.xiangmu[i]["projectTime"],
          company: this.xiangmu[i]["company"],
          position: this.xiangmu[i]["position"],
          size: this.xiangmu[i]["size"],
          industry: this.xiangmu[i]["industry"],
          nature: this.xiangmu[i]["nature"],
          content: this.xiangmu[i]["content"],
        });
      }
    }, 1000);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/Api/user/saveProject", {
              token: localStorage.getItem("token"),
              project: this.ruleForm.getData,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message.success("修改成功");
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
          this.zhankai = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.getData.push({
            id: "",
            projectTime: [
              this.ruleForm.formData[0]["startTime"],
              this.ruleForm.formData[0]["endTime"],
            ],
            company: this.ruleForm.formData[0]["company"],
            position: this.ruleForm.formData[0]["position"],
            size: this.ruleForm.formData[0]["size"],
            industry: this.ruleForm.formData[0]["industry"],
            nature: this.ruleForm.formData[0]["nature"],
            content: this.ruleForm.formData[0]["content"],
          });
          this.$refs.ruleForm2.resetFields();
          this.$axios
            .post("/Api/user/saveProject", {
              token: localStorage.getItem("token"),
              project: this.ruleForm.getData,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message.success(res.data.message);
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset() {
      this.zhankai = true;
    },
    reset1() {
      this.$refs.ruleForm2.resetFields();
    },
    edit(e) {
      this.zhankai = false;
      this.showNum = e;
    },
    del(e) {
      this.ruleForm.getData.splice(e, 1);
      this.$axios
        .post("/Api/user/saveProject", {
          token: localStorage.getItem("token"),
          project: this.ruleForm.getData,
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success("删除成功");
          } else {
            this.$message({
              message: res.data.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 25px;
    }
  }
}
.ctx {
  margin-top: 50px;
  .titles {
    color: #323232;
    font-size: 14px;
    margin-bottom: 16px;
  }
  /deep/ .el-textarea__inner {
    min-height: 120px !important;
  }
}
.zhanshi {
  background: #fcfcfd;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #f1f2f4;
  position: relative;
  .posi {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    .pa1 {
      width: 24px;
      height: 24px;
      background: #409eff;
      @extend .cflex;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
    }
    .pa2 {
      margin-left: 10px;
    }
  }
  .a1 {
    color: #898989;
    font-size: 14px;
    display: flex;
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 8px;
    }
    .b1 {
      margin-right: 20px;
    }
    .b2 {
      color: #292e41;
    }
  }
}
.addls1 {
  /deep/ .el-form-item__label {
    display: block;
    float: unset;
    text-align-last: left;
  }
  /deep/ .el-date-editor {
    width: 100%;
  }
}
.ls3 {
  @extend .fsb;
  /deep/ .el-form-item {
    width: 260px;
  }
  /deep/ .el-select {
    width: 100%;
  }
}
/deep/ .el-textarea__inner {
  min-height: 120px !important;
}
/deep/ .el-button:focus,
.el-button:hover {
  color: #606266;
  border-color: #dcdfe6;
  background-color: #fff;
}
/deep/ .el-button--primary:focus,
.el-button--primary:hover {
  background: #409eff;
  border-color: #409eff;
  color: #fff;
}
</style>
