<template>
  <div class="container">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" class="t">
        <template slot="title">
          <img src="../assets/img/pcimg31.png" alt="" class="a1" />
          <div class="a2">其它信息</div>
        </template>
        <div class="ctx">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
            <el-form-item label="其它信息" prop="content">
              <el-input
                v-model="ruleForm.content"
                placeholder="请输入其它信息"
                clearable
                type="textarea"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item style="text-align: right">
              <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "cv9",
  props: {
    other: String,
  },
  data() {
    return {
      activeNames: ["1"],
      upfiles: "",
      ruleForm: {
        content: "",
      },
      rules: {
        // content: [
        //   {
        //     required: true,
        //     message: "其它信息不能为空",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.ruleForm.content = this.other;
    }, 1000);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/Api/user/saveOther", {
              token: localStorage.getItem("token"),
              other: this.ruleForm.content,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message.success(res.data.message);
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
        console.log(valid);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 25px;
    }
  }
}
.ctx {
  margin-top: 50px;
  .titles {
    color: #323232;
    font-size: 14px;
    margin-bottom: 16px;
  }
  /deep/ .el-textarea__inner {
    min-height: 120px !important;
  }
}
</style>
