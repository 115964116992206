<template>
  <div class="container">
    <div class="conts">
      <el-descriptions :column="3" border>
        <el-descriptions-item label="姓名">{{
          datas.realname
        }}</el-descriptions-item>
        <el-descriptions-item label="性别">{{
          datas.gender == 1 ? "男" : "女"
        }}</el-descriptions-item>
        <el-descriptions-item label="照片">
          <img
            :src="datas.avatarUrl"
            alt=""
            style="height: 50px"
            v-if="datas.avatarUrl"
          />
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="籍贯">
          {{ datas.native_place }}
        </el-descriptions-item>
        <el-descriptions-item label="证件号码">
          <div v-if="datas.identification">{{ datas.identification }}</div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="出生日期">
          <div>{{ datas.birthday }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="手机号码">
          <div>{{ datas.phone }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="邮箱">
          <div>{{ datas.email }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="身高(cm)">
          <div>{{ datas.high }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="政治面貌">
          <div>{{ datas.political }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="婚姻状况">
          <div>{{ datas.marriage == 1 ? "已婚" : "未婚" }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="兴趣爱好">
          <div v-if="datas.hobby">{{ datas.hobby }}</div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="微信号">
          <div>{{ datas.wxcode }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="现居住地">
          <div>{{ datas.province }}-{{ datas.city }}-{{ datas.area }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="户口所在地">
          <div v-if="datas.regProvince">
            {{ datas.regProvince }}-{{ datas.regCity }}-{{ datas.regArea }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="学历">
          <div>{{ datas.education }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="工作经验">
          <div>{{ datas.experience }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="应聘岗位">
          <div>{{ intention.position }}</div>
        </el-descriptions-item>
        <el-descriptions-item label="期望月薪">
          <div v-if="intention.wages">
            {{ intention.wages }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="期望工作地区">
          <div v-if="intention.province">
            {{ intention.province }}-{{ intention.city }}-{{ intention.area }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="期望从事职业">
          <div v-if="intention.occupation">
            {{ intention.occupation }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="期望从事行业">
          <div v-if="intention.industry">
            {{ intention.industry }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="目前状况">
          <div v-if="intention.situation">
            {{ intention.situation }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="到岗时间">
          <div v-if="intention.arrivalTime">
            {{ intention.arrivalTime }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
        <el-descriptions-item label="工作性质">
          <div v-if="intention.nature">
            {{ intention.nature }}
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" border direction="vertical">
        <el-descriptions-item
          label="自我评述"
          contentClassName="css1"
          :contentStyle="{ 'text-align': 'left' }"
        >
          <div v-if="datas.comment">{{ datas.comment }}</div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" border direction="vertical">
        <el-descriptions-item
          label="教育经历"
          contentClassName="css1"
          :contentStyle="{ 'text-align': 'left' }"
        >
          <div v-if="educational.length > 0">
            <div v-for="(item, index) in educational" :key="index">
              就学时间：{{ item.schoolTime[0] }}~{{
                item.schoolTime[1]
              }}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 学校名称：{{
                item.school
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 学位/学历：
              {{ item.degree }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 就学专业：{{
                item.major
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 专业描述：{{
                item.professional
              }}
            </div>
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" border direction="vertical">
        <el-descriptions-item
          label="培训经历"
          contentClassName="css1"
          :contentStyle="{ 'text-align': 'left' }"
        >
          <div v-if="training.length > 0">
            <div v-for="(item, index) in training" :key="index">
              培训时间：{{ item.trainingTime[0] }}~{{
                item.trainingTime[1]
              }}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 校训机构/学校：{{
                item.motto
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 培训描述：
              {{ item.remark }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 专业描述：{{
                item.represent
              }}
            </div>
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" border direction="vertical">
        <el-descriptions-item
          label="工作经历"
          contentClassName="css1"
          :contentStyle="{ 'text-align': 'left' }"
        >
          <div v-if="working.length > 0">
            <div v-for="(item, index) in working" :key="index">
              工作时间：{{ item.workingTime[0] }}~{{
                item.workingTime[1]
              }}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 公司名称：{{
                item.company
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 担任职位：
              {{ item.position }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              公司规模：{{ item.size }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              行业类别：{{ item.industry }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              企业性质：{{ item.nature }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              工作内容：{{ item.content }}
            </div>
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" border direction="vertical">
        <el-descriptions-item
          label="项目经历"
          contentClassName="css1"
          :contentStyle="{ 'text-align': 'left' }"
        >
          <div v-if="project.length > 0">
            <div v-for="(item, index) in project" :key="index">
              工作时间：{{ item.projectTime[0] }}~{{
                item.projectTime[1]
              }}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 公司名称：{{
                item.company
              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 担任职位：
              {{ item.position }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              公司规模：{{ item.size }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              行业类别：{{ item.industry }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              企业性质：{{ item.nature }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              工作内容：{{ item.content }}
            </div>
          </div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="1" border direction="vertical">
        <el-descriptions-item
          label="其他信息"
          contentClassName="css1"
          :contentStyle="{ 'text-align': 'left' }"
        >
          <div v-if="datas.other">{{ datas.other }}</div>
          <div v-else>暂无</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { isLogins } from "../comjs/com";
export default {
  name: "Ynlan",
  data() {
    return {
      datas: "",
      intention: "",
      educational: "",
      training: "",
      working: "",
      project: "",
    };
  },
  mounted() {
    isLogins();
    this.$axios
      .post("/Api/user/get", {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        this.datas = res.data.data.data;
        this.intention = res.data.data.intention;
        this.educational = res.data.data.educational;
        this.training = res.data.data.training;
        this.working = res.data.data.working;
        this.project = res.data.data.project;
        console.log(res.data.data);
      });
  },
};
</script>

<style lang='scss' scoped>
.container {
  @extend .minwid;
  padding: 80px 0;
  background: #fff;
  .conts {
    @extend .tutWid;
  }
  /deep/ .el-descriptions-item__content {
    text-align: center;
  }
}
</style>