<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="文件上传中..."
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" class="t">
        <template slot="title">
          <img src="../assets/img/pcimg31.png" alt="" class="a1" />
          <div class="a2">附件</div>
        </template>
        <div class="ctx">
          <el-upload
            class="upload-demo"
            action=""
            :before-upload="beforeAvatarUpload"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="handleFileSuccess"
            :http-request="uploadImgList"
            :file-list="upfiles"
            :before-remove="beforeRemove"
          >
            <el-button size="small" icon="el-icon-upload">上传文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传.pdf,.docx,.doc文件！，且不超过5MB
            </div>
          </el-upload>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "cv10",
  props: {
    addfiles: Array,
  },
  data() {
    return {
      activeNames: ["1"],
      upfiles: [],
      loading: false,
    };
  },
  mounted() {
    setTimeout(() => {
      for (let i = 0; i < this.addfiles.length; i++) {
        if (this.addfiles[i].file) {
          this.upfiles.push({
            name: this.addfiles[i].name + "的附件",
            url: this.addfiles[i].url,
          });
        }
      }
    }, 1000);
  },
  methods: {
    beforeRemove(){
      this.$axios.post('/Api/user/delFile',{token:localStorage.getItem('token')}).then((res) => {
        this.$message.success(res.data.message)
      })
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isDoc = file.type === "application/msword";
      const isDocx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      const isPdf = file.type === "application/pdf";
      const isPpt = file.type === "application/vnd.ms-powerpoint";
      const isPptx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      const isLt2M = file.size / 1024 / 1024 < 5;
      const isXls = file.type === "application/vnd.ms-excel";
      const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      if (!isDoc && !isDocx && !isPdf && !isPpt && !isPptx && !isXls && !isXlsx && !isJPG && !isPng) {
        this.$message.warning("只能上传.pdf,.docx,.doc,.ppt,.pptx文件！");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传文件大小不能超过 5MB!");
        return false;
      }
    },
    handleExceed() {
      this.$message.warning("当前限制上传1个文件，要替换的话，请先删除已上传的");
    },
    handleFileSuccess(response, file, fileList) {
      console.log(fileList, 123);
      // this.upfiles = fileList;
      // this.$message.success("上传成功");
    },
    uploadImgList(params) {
      this.upfiles = [];
      this.loading = true;
      const file = params.file,
        form = new FormData();
      form.append("file", file);
      form.append("token", localStorage.getItem("token"));
      this.$axios.post("/Api/user/saveFile", form).then((res) => {
        console.log(res.data, file.name);
        if (res.data.code == 1) {
          this.upfiles.push({
            name: file.name,
            url: res.data.data.file,
          });
          this.loading = false;
          this.$message.success(res.data.message);
        } else {
          this.$message({
            message: res.data.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 25px;
    }
  }
}
.ctx {
  margin-top: 50px;
  /deep/ .el-button {
    @extend .cflex;
    color: #637787;
    .el-icon-upload {
      font-size: 16px;
    }
    &:hover,
    &:focus {
      background: #fff;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>
