<template>
  <div>
    <div class="banner">
      <img :src="dataImg1" alt="" />
    </div>
    <div class="conts">
      <img src="../assets/img/pcimg23.png" alt="" class="tops" />
      <div class="mids">
        <div class="a1">
          广东豪美新材股份有限公司创建于1991年，是一家集专业研发、制造、销售各种铝合金挤压材于一体的专业化、集团化的高新技术企业。拥有国内乃至全球最先进、自动化程度最高的铝型材现代化生产基地，致力于向产业链上下游拓展，重点打造高品质、环保节能新材，产品已延伸并覆盖到电子工业、机械工业、矿山冶金、IT产业以及能源和交通运输等多个行业，产品畅销美国、德国、加拿大、意大利、瑞士、新加坡、文莱等六十多个国家和地区。公司下辖三家子公司，年度销售总额逾40亿元。
        </div>
        <div class="a1">
          公司拥有“国家认定企业技术中心”、“广东省工程技术研究开发中心”、“广东省博士后创新实践基地”等，并入选“中国建筑铝型材行业二十强”。
        </div>
        <div class="a1">
          近年来，豪美新材践行“让每一块铝更有价值”的经营理念，不断创新开拓，积极进取，新材料、新产业、新项目陆续投产，2020年5月于深交所正式上市，股票代码002988；
          现公司不断壮大发展，诚邀有志者加盟，欢迎您的加入：
        </div>
      </div>
      <div class="foots">
        <img :src="dataImg2" alt="" class="a1" />
        <img
          src="../assets/img/pcimg24.png"
          alt=""
          class="a2"
          @click="outerVisible = true"
        />
      </div>
      <div class="more">
        <a href="http://www.haomei-alu.com/" target="_blank"
          >了解更多请移步官网&nbsp;>></a
        >
      </div>
    </div>
    <el-dialog
      title="视频介绍"
      :visible.sync="outerVisible"
      custom-class="addvideos"
      @open="onOpenDialog"
    >
      <video
        :src="Host + '/index/media/ahout.mp4'"
        controls
        autoplay
        id="videoID"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import { Host } from "../comjs/com";
export default {
  name: "Join",
  data() {
    return {
      outerVisible: false,
      Host: Host,
      dataImg1: "",
      dataImg2: "",
    };
  },
  mounted() {
    this.$axios.post("/Api/photo/getphoto", { id: 5 }).then((res) => {
      this.dataImg1 = this.Host + res.data.data.content[0].img;
      this.dataImg2 = this.Host + res.data.data.content[1].img;
    });
  },
  methods: {
    onOpenDialog() {
      document.getElementById("videoID").currentTime = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  @extend .minwid;
  img {
    display: block;
    width: 100%;
  }
}
.conts {
  @extend .tutWid;
  @extend .minwid;
}
.tops {
  margin: 80px auto 60px;
  display: block;
}
.mids {
  padding-bottom: 30px;
  .a1 {
    color: #545664;
    font-size: 15px;
    line-height: 2;
    text-indent: 30px;
    margin-bottom: 35px;
  }
}
.foots {
  height: 400px;
  position: relative;
  .a1 {
    display: block;
    width: 100%;
  }
  .a2 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -31px 0 0 -31px;
    cursor: pointer;
  }
}
.more {
  margin: 70px 0;
  @extend .cflex;
  a {
    background: #e7f1fe;
    border-radius: 35px;
    padding: 20px 22px;
    @extend .color;
    font-size: 14px;
  }
}
.addvideos {
  video {
    width: 100%;
    display: block;
  }
}
</style>
