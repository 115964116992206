<template>
  <div
    class="container"
    :class="`${!isTops ? 'container-actives' : ''}${
      $route.name !== 'index' ? ' noindex container-actives' : ''
    }`"
  >
    <div class="conts">
      <a href="/" class="les">
        <img src="../assets/img/pcimg4fff.png" alt="" class="hideimg1" />
        <img src="../assets/img/pcimg4.png" alt="" class="hideimg2" />
      </a>
      <div class="mids">
        <a href="/" class="ls" :class="$route.name == 'index' ? 'actives' : ''">首页</a>
        <router-link
          to="/campus"
          :class="
            $route.name == 'campus' || $route.name == 'campus-content' ? 'actives' : ''
          "
          class="ls"
          >校园招聘</router-link
        >
        <router-link
          to="/society"
          :class="
            $route.name == 'society' || $route.name == 'society-content' ? 'actives' : ''
          "
          class="ls"
          >社会招聘</router-link
        >
        <router-link
          to="/internal"
          :class="
            $route.name == 'internal' || $route.name == 'internal-content'
              ? 'actives'
              : ''
          "
          class="ls"
          >有奖推荐</router-link
        >
        <router-link
          to="/master"
          :class="
            $route.name == 'master' || $route.name == 'master-content' ? 'actives' : ''
          "
          class="ls"
          >硕博专栏</router-link
        >
        <router-link to="/join" :class="$route.name == 'join' ? 'actives' : ''" class="ls"
          >人在豪美</router-link
        >
        <a href="http://www.haomei-alu.com/" class="ls" target="_blank">豪美官网</a>
      </div>
      <div class="ris">
        <a href="javascript:;" @click="drawer = true" class="nav">栏目</a>
        <router-link to="/register" class="a1" v-if="!isLogin">注册</router-link>
        <router-link to="/login" class="a2" v-if="!isLogin">登录</router-link>
        <router-link to="/cv" v-if="isLogin"><svg t="1662532834143" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4718" width="35" height="35"><path d="M334.336 616.3456c0 11.5712 9.5232 21.0944 21.0944 21.0944h315.4944c11.5712 0 21.0944-9.5232 21.0944-21.0944s-9.5232-21.0944-21.0944-21.0944H355.4304c-11.6736 0-21.0944 9.5232-21.0944 21.0944zM670.9248 702.0544H355.4304c-11.5712 0-21.0944 9.5232-21.0944 21.0944s9.5232 21.0944 21.0944 21.0944h315.4944c11.5712 0 21.0944-9.5232 21.0944-21.0944s-9.5232-21.0944-21.0944-21.0944z" p-id="4719" fill="#117afb"></path><path d="M586.9568 165.888H272.4864c-23.2448 0-42.1888 18.944-42.1888 42.1888v607.8464c0 23.2448 18.944 42.1888 42.1888 42.1888H751.616c23.2448 0 42.1888-18.944 42.1888-42.1888V372.736l-206.848-206.848z m27.4432 87.1424l97.1776 97.1776h-89.9072c-3.8912 0-7.2704-3.3792-7.2704-7.2704v-89.9072z m137.1136 562.7904s0 0.1024 0 0l-479.0272 0.1024s-0.1024 0-0.1024-0.1024V208.1792s0-0.1024 0.1024-0.1024h297.984l1.6384 1.6384v133.12c0 27.2384 22.3232 49.4592 49.4592 49.4592h129.9456v423.5264z" p-id="4720" fill="#117afb"></path><path d="M428.8512 388.7104c37.1712 0 67.2768-30.1056 67.2768-67.2768 0-37.1712-30.1056-67.2768-67.2768-67.2768S361.472 284.16 361.472 321.4336c0 37.1712 30.1056 67.2768 67.3792 67.2768z m0-97.6896c16.7936 0 30.4128 13.6192 30.4128 30.4128s-13.6192 30.4128-30.4128 30.4128-30.4128-13.6192-30.4128-30.4128 13.6192-30.4128 30.4128-30.4128zM355.4304 504.7296c11.6736 0 21.0944-9.4208 21.0944-21.0944 0-22.8352 23.9616-42.1888 52.3264-42.1888s52.3264 19.2512 52.3264 42.1888c0 11.6736 9.4208 21.0944 21.0944 21.0944s21.0944-9.4208 21.0944-21.0944c0-46.4896-42.3936-84.3776-94.5152-84.3776s-94.5152 37.888-94.5152 84.3776c0 11.6736 9.4208 21.0944 21.0944 21.0944z" p-id="4721" fill="#117afb"></path></svg></router-link>
        <el-dropdown class="showlogins" v-if="isLogin">
          <div>
            <div class="sa1">{{ phones }}</div>
            <img src="../assets/img/pcimg30.png" alt="" />
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link to="/personal">个人中心</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="outlogins">退出登录</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 小于912显示栏目 -->
    <el-drawer
      :visible.sync="drawer"
      direction="ltr"
      :with-header="false"
      :modal="false"
      size="60%"
      custom-class="phonenavs"
    >
      <a href="#" class="ls" :class="$route.name == 'index' ? 'actives' : ''">首页</a>
      <a href="#" class="ls">校园招聘</a>
      <a href="#" class="ls">社会招聘</a>
      <a href="#" class="ls">有奖推荐</a>
      <a href="#" class="ls">硕博专栏</a>
      <a href="#" class="ls">人在豪美</a>
      <a href="http://www.haomei-alu.com/" class="ls" target="_blank">豪美官网</a>
    </el-drawer>
  </div>
</template>

<script>
import { outLogin } from "../comjs/com";
export default {
  name: "Tops",
  props: {
    isTops: Boolean,
  },
  data() {
    return {
      drawer: false,
      isLogin: false,
      phones: "",
    };
  },
  methods: {
    Logins(e) {
      this.isLogin = e;
    },
    Phones(e) {
      this.phones = e;
    },
    outlogins() {
      outLogin();
      this.isLogin = false;
    },
  },
  mounted() {
    let logins = localStorage.getItem("Logins");
    if (logins) {
      this.isLogin = true;
    }
    let Phone = localStorage.getItem("Phone");
    if (Phone) {
      this.phones = Phone;
    }
  },
  created() {
    this.$bus.$on("Logins", this.Logins);
    this.$bus.$on("Phones", this.Phones);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
  @extend .fsb;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transition: 0.5s all;
  z-index: 10;
  .les{width: 152px;
    img{width: 100%;}
  }
  .hideimg2 {
    display: none;
  }
  .conts {
    @media only screen and (max-width: 912px) {
      padding: 10px 0;
    }
    @extend .tutWid;
    @extend .fsb;
    align-items: center;
    .mids {
      @media only screen and (max-width: 1240px) {
        flex: 1;
        box-sizing: border-box;
        padding: 0 20px;
      }
      @extend .fsb;
      .ls {
        width: 110px;
        height: 100px;
        @extend .cflex;
        padding-top: 20px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        text-align: center;
        &:not(:nth-last-of-type(1)) {
          margin-right: 0;
        }
        @media only screen and (max-width: 1240px) {
          width: 80px;
        }
        @media only screen and (max-width: 912px) {
          display: none;
        }
      }
      .actives {
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          @extend .bg;
          position: absolute;
          z-index: -1;
          @extend .color;
        }
      }
    }
    .ris {
      @media only screen and (max-width: 912px) {
        margin-top: 0;
      }
      @extend .fsb;
      margin-top: 20px;
      a {
        color: #fff;
      }
      .a1 {
        font-size: 16px;
        margin-right: 24px;
      }
      .a2 {
        width: 90px;
        height: 30px;
        border-radius: 25px;
        border: 1px solid #fff;
        @extend .cflex;
        &:hover {
          background: #fff;
          @extend .color;
        }
      }
      .nav {
        margin-right: 24px;
        @media only screen and (min-width: 912px) {
          display: none;
        }
      }
    }
  }

  &-actives {
    background: #fff;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.05);
    .hideimg1 {
      display: none;
    }
    .hideimg2 {
      display: block;
    }
    .conts {
      .mids {
        .actives {
          @extend .color;
          &::before {
            background: url(../assets/img/pcimg20.png);
          }
        }
        .ls {
          color: #646464;
        }
      }
      .ris {
        align-items: center;
        a {
          @extend .color;
        }
        .a2 {
          @extend .border;
        }
      }
    }
  }
}
.phonenavs {
  .ls {
    display: block;
    color: #000;
    font-size: 18px;
    box-sizing: border-box;
    padding-left: 20px;
    margin-top: 5vh;
  }
  .actives {
    @extend .color;
  }
}
.noindex {
  position: sticky;
  @extend .minwid;
  top: 0;
}
.container {
  .showlogins {
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .sa1 {
      color: #fff;
      font-size: 15px;
      margin-right: 10px;
    }
    img {
      width: 40px;
      display: block;
    }
  }
}
.container-actives {
  .showlogins {
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .sa1 {
      color: #646464;
      font-size: 15px;
      margin-right: 10px;
    }
    img {
      width: 40px;
      display: block;
    }
  }
}
/deep/ .el-dropdown-menu__item {
  a {
    color: #606266;
    display: block;
    width: 100%;
    height: 1005;
    &:hover {
      color: #66b1ff;
    }
  }
}
</style>
