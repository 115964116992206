import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  Button, Select, Dropdown, DropdownMenu, DropdownItem, Menu, Submenu, MenuItem, MenuItemGroup, Radio, RadioGroup, RadioButton, Tooltip, Breadcrumb, BreadcrumbItem, Row, Col, Card, Input, InputNumber, Autocomplete, Alert, Table, TableColumn, Popover, Tag, Pagination, Tabs, TabPane, Image, Calendar, DatePicker, TimePicker, Form, FormItem, Message, Dialog, Option, OptionGroup, Container, Header, Aside, Main, Footer, Drawer, Loading, Checkbox, CheckboxButton, CheckboxGroup, MessageBox, Collapse, CollapseItem, Upload, Cascader, CascaderPanel, Steps, Step,Carousel,
  CarouselItem,Descriptions,DescriptionsItem
} from 'element-ui';
import moment from 'moment'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Cookies from 'js-cookie'
import VueScroll from '@david-j/vue-j-scroll';

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.use(VueScroll);
Vue.use(VueAwesomeSwiper)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Button)
Vue.use(Select)
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Autocomplete);
Vue.use(Alert);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Tag);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Dialog);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Drawer);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Upload);
Vue.use(Cascader);
Vue.use(CascaderPanel);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Loading.directive);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;

Vue.prototype.Cook = Cookies
Vue.prototype.$bus = new Vue()

export default new Vue()

Vue.config.productionTip = false

Vue.filter('tmoment', function (input, fmtstring) {
  return moment.unix(input).format(fmtstring)
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
  console.log(to, from.name)
  let getcode = localStorage.getItem('code')
  if (getcode) {
    if (to.query.code) {
      localStorage.setItem("code", to.query.code);
    }
    if (to.query.id && !to.query.code) {
      router.push(to.fullPath + '&code=' + getcode)
      // router.push(to.fullPath + '&code=' + getcode)
    } else {
      if (!to.query.code && !to.query.state && !to.query.xy) {
        router.push(to.fullPath + '?code=' + getcode)
      }
    }
  } else {
    if (to.query.code) {
      localStorage.setItem("code", to.query.code);
    }
  }
  if (!sessionStorage.getItem('oneCode')) {
    if (!localStorage.getItem('Logins') && to.query.code || localStorage.getItem('code')) {
      axios.post('/Api/user/setsharelog', {
        code: to.query.code ? to.query.code : localStorage.getItem('code')
      }).then(() => {
        sessionStorage.setItem('oneCode', 1)
      })
    }
  }

  next();
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
