<template>
  <div class="container">
    <div class="ibanner" v-loading="loading">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in swiperUrl" :key="index">
          <img :src="webHost + item.img" alt="" />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="conts">
        <!-- <div class="a1">豪 美 集 团 实 习 生 招 聘</div> -->
        <img src="../assets/img/pcimg3.png" alt="" class="a2" />
        <div style="display: flex">
          <a
            href="javascript:;"
            class="a3"
            @click="toudi1"
            style="margin-right: 8px"
            >创建简历</a
          >
          <!-- <a href="javascript:;" class="a3" @click="toudi">立即投递</a> -->
          <router-link class="a3" to="/campus" style="margin-right: 8px"
            >校园招聘</router-link
          >
          <router-link class="a3" to="/society" style="margin-right: 8px"
            >社会招聘</router-link
          >
          <router-link class="a3" to="/internal" style="margin-right: 8px"
            >有奖推荐</router-link
          >
          <router-link class="a3" to="/master">硕博专栏</router-link>
        </div>
      </div>
    </div>
    <div class="iliucheng">
      <div class="com1">
        <img src="../assets/img/pcimg5.png" alt="" class="a1" />
        <div class="a2 dinlight">Recruitment Process</div>
      </div>
      <div class="mids">
        <div class="ls">
          <div class="a1">
            <img src="../assets/img/pcimg7.png" alt="" />
          </div>
          <div class="a2">简历筛选</div>
        </div>
        <img src="../assets/img/pcimg11.png" alt="" />
        <div class="ls">
          <div class="a1">
            <img src="../assets/img/pcimg8.png" alt="" />
          </div>
          <div class="a2">面试评估</div>
        </div>
        <img src="../assets/img/pcimg11.png" alt="" />
        <div class="ls">
          <div class="a1">
            <img src="../assets/img/pcimg9.png" alt="" />
          </div>
          <div class="a2">人才测评</div>
        </div>
        <img src="../assets/img/pcimg11.png" alt="" />
        <div class="ls">
          <div class="a1">
            <img src="../assets/img/pcimg10.png" alt="" />
          </div>
          <div class="a2">Offer发放</div>
        </div>
      </div>
      <a href="#" class="com2">招聘须知</a>
    </div>
    <div class="iabout">
      <div class="conts">
        <div class="com1">
          <img src="../assets/img/pcimg6.png" alt="" class="a1" />
          <div class="a2 dinlight">About Haomei</div>
        </div>
        <div class="tops">
          <div class="les">
            <div class="a1">{{ aboutInfo.subtitle }}</div>
            <div class="a2">
              {{ aboutInfo.remark }}
            </div>
            <router-link to="/about" class="com2">了解更多</router-link>
          </div>
          <div class="ris">
            <img :src="webHost + aboutInfo.img" alt="" />
          </div>
        </div>
        <div class="mids">
          <a href="http://www.haomei-alu.com/about" target="_blank" class="ls">
            <img src="../assets/img/pcimg13.jpg" alt="" />
          </a>
          <!-- <a href="http://www.jingmei-alu.com/" target="_blank" class="ls">
            <img src="../assets/img/pcimg16.jpg" alt="" />
          </a> -->
          <a href="javascript:;" class="ls">
            <img src="../assets/img/pcimg30.jpg" alt="" />
          </a>
          <a href="http://www.bucalu.com/" target="_blank" class="ls">
            <img src="../assets/img/pcimg14.jpg" alt="" />
          </a>
          <a href="http://www.kejian-gd.com/" target="_blank" class="ls">
            <img src="../assets/img/pcimg15.jpg" alt="" />
          </a>
        </div>
        <div class="foots">
          <img src="../assets/img/pcimg17.png" alt="" class="les" />
          <div class="ris">
            <div class="a1" style="margin-left:auto;margin-right:auto;">
              <img src="../assets/img/pcimg33.png" alt="" />
            </div>
            <div class="a2">扫码关注豪美新材招聘</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="gonggao">
      <div class="a1" @click="visible = !visible">
        <img :src="webHost + gonggaoInfo.img" alt="" />
      </div>
      <div class="a2" v-if="visible">
        <div class="b1">
          {{ gonggaoInfo.introduction }}
        </div>
      </div>
    </div> -->
    <div class="zhaoinfo" v-if="gun1">
      <div class="a1">
        <div>公告栏</div>
        <i class="el-icon-close" @click="gun1 = false"></i>
      </div>
      <div @click.stop="click1($event)" class="addcss1" :class="gongshow?'addcss2':'addcss1'">
        <vue-seamless-scroll
          :class-option="classOption"
          :data="gonggaoInfo"
          class="seamless-warp"
        >
          <div class="item">
            <div v-for="(item, index) in gonggaoInfo" :key="index" :id="index" class="row">
              <span
                class="title"
                v-text="item.subtitle"
                @click="show(index)"
              ></span>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class="zhaoinfo hots" v-if="gun2">
      <div class="a1">
        <div>热招</div>
        <i class="el-icon-close" @click="gun2 = false"></i>
      </div>
      <div class="addcss1">
        <vue-seamless-scroll
          :class-option="classOption"
          :data="zhaoInfo"
          class="seamless-warp"
        >
          <div class="item">
            <div v-for="(item, index) in zhaoInfo" :key="index" :id="index" class="row">
              <router-link :to="'/society-content?id='+ item.id" class="title" style="font-size:16px">{{item.title}}</router-link>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <el-dialog title="公告内容" :visible.sync="dialogVisible" width="30%">
      <span>{{ addcontends }}</span>
    </el-dialog>
  </div>
</template>

<script>
import { Application, Host } from "../comjs/com";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Index",
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      dialogVisible: false,
      gongshow:false,
      addcontends: "",
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 800,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      showHot: true,
      loading: false,
      webHost: Host,
      swiperUrl: [],
      aboutInfo: [],
      gonggaoInfo: [],
      visible: true,
      zhaoInfo: "",
      gun1:true,
      gun2:true,
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    this.$axios
      .all([
        this.$axios.post("/Api/photo/getphoto", { id: 2 }),
        this.$axios.post("/Api/photo/getphoto", { id: 3 }),
        this.$axios.post("/Api/photo/getphoto", { id: 7 }),
        this.$axios.post("/Api/post/postsList", {
          cateid: 2,
          limit: 100,
          page: 1,
          ishot: 1,
        }),
      ])
      .then(
        this.$axios.spread((res1, res2, res3, res4) => {
          this.swiperUrl = res1.data.data.content;
          this.aboutInfo = res2.data.data.content[0];
          this.gonggaoInfo = res3.data.data.content;
          this.zhaoInfo = res4.data.data.data;
        })
      );
  },
  methods: {
    click1(e) {
      let e1
      for (let i = 0; i < e.path.length; i++) {
        if(e.path[i].className == 'row'){
          e1 = e.path[i]
          break
        }
      }
      this.addcontends = this.gonggaoInfo[e1.id].introduction;
      this.dialogVisible = true;
    },
    show(e) {
      this.addcontends = this.gonggaoInfo[e].introduction;
      this.dialogVisible = true;
    },
    toudi() {
      Application();
      let isLog = localStorage.getItem("Logins");
      if (isLog) {
        this.$router.push("/campus");
      }
    },
    toudi1() {
      let islogs = localStorage.getItem("Logins");
      if (!islogs) {
        this.$confirm("当前用户尚未登录，不能创建简历", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      } else {
        this.$router.push("/cv");
      }
    },
    fn(arr, num) {
      let newArr = [];
      const total = Math.ceil(arr.length / num);
      for (let i = 0; i < total; i++) {
        let a = arr.slice(i * num, (i + 1) * num);
        newArr.push(a);
      }
      return newArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.ibanner {
  height: 1000px;
  width: 100%;
  overflow: hidden;
  @extend .minwid;
  position: relative;
  .conts {
    position: absolute;
    width: 100%;
    height: 100%;
    @extend .cflex;
    flex-direction: column;
    z-index: 1;
    top: 0;
    left: 0;
    color: #fff;
    .a1 {
      font-size: 20px;
      font-weight: 200;
      letter-spacing: 10px;
    }
    .a2 {
      margin: 0 0 54px;
    }
    .a3 {
      @extend .bg;
      color: #fff;
      text-align: center;
      border-radius: 35px;
      padding: 12px 22px;
      font-size: 15px;
    }
  }
  .swiper-container {
    height: 100%;
  }
  .swiper-wrapper {
    height: 100%;
    .swiper-slide {
      height: 100%;
      img {
        @extend .imgcover;
      }
    }
  }
  /deep/ .swiper-pagination {
    bottom: 40px;
    @extend .cflex;
    z-index: 3;
    width: 100%;
    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
      background: #fff;
      opacity: 0.6;
      border-radius: 50%;
      margin: 0 10px;
      &-active {
        width: 30px;
        border-radius: 20px;
        opacity: 1;
      }
    }
  }
  /deep/ .swiper-button-next,
  .swiper-button-prev {
    background: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    @extend .cflex;
    left: 60px;
    color: #fff;
    font-size: 18px;
    z-index: 10;
    transition: 0.5s all;
    &:hover {
      @extend .bg;
      @extend .border;
    }
  }
  /deep/ .swiper-button-next {
    left: unset;
    right: 60px;
  }
}
.iliucheng {
  padding-bottom: 80px;
  @extend .tutWid;
  overflow: hidden;
  .com1 {
    margin: 80px 0 90px;
  }
  .mids {
    @extend .fsb;
    width: 985px;
    margin: 0 auto 75px;
    .ls {
      @extend .fsb;
      flex-direction: column;
      .a1 {
        width: 72px;
        height: 72px;
        img {
          @extend .imgcontain;
        }
      }
      .a2 {
        color: #505050;
        font-size: 20px;
        margin-top: 24px;
      }
    }
  }
  .com2 {
    margin: 0 auto;
  }
}
.iabout {
  background: #f8fbff;
  @extend .minwid;
  .conts {
    @extend .tutWid;
  }
  .com1 {
    padding: 88px 0 54px;
  }
  .tops {
    padding-bottom: 60px;
    @extend .fsb;
    align-items: flex-start;
    .les {
      margin-top: 20px;
      width: 560px;
      .a1 {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: bold;
      }
      .a2 {
        color: #545664;
        font-size: 15px;
        line-height: 1.9;
        text-indent: 30px;
        @extend .centerlf;
        margin: 35px 0;
      }
    }
    .ris {
      width: 600px;
      height: 368px;
      overflow: hidden;
      &:hover img {
        transform: scale(1.2);
      }
      img {
        display: block;
        transition: all 0.6s;
      }
    }
  }
  .mids {
    @extend .fsb;
    img {
      transition: all 0.6s;
      display: block;
      &:hover {
        margin-top: -20px;
      }
    }
  }
  .foots {
    padding: 80px 0 58px;
    @extend .fsb;
    .les {
      width: 624px;
    }
    .ris {
      .a1 {
        width: 120px;
        height: 120px;
        position: relative;
        box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.05);
        margin-bottom: 12px;
        &:before {
          content: "";
          width: 100%;
          height: 2px;
          @extend .bg;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.3;
          animation: saoma 1.5s ease-in-out infinite alternate;
        }
        img {
          width: 120px;
          display: block;
        }
      }
      .a2 {
        font-size: 14px;
        color: #646464;
        text-align: center;
      }
    }
  }
}
.gonggao {
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 100;
  .a1 {
    @extend .bg;
    padding: 8px 5px 2px;
    cursor: pointer;
  }
  .a2 {
    width: 200px;
    position: absolute;
    left: 70px;
    margin-left: 12px;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ebeef5;
    font-size: 14px;
    color: #606266;
    top: 0;
    margin-top: calc(50% - 0px);
    box-sizing: border-box;
    .b1 {
      max-height: 250px;
      @extend .scrollbar1;
      box-sizing: border-box;
      width: 200px;
      padding: 20px;
    }
    &::after {
      content: "";
      border-width: 6px;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      left: -6px;
      top: 15px;
      border-right-color: #fff;
      border-left-width: 0;
    }
  }
}
.zhaoinfo {
  overflow: hidden;
  z-index: 100;
  width: 280px;
  height: auto;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 15%;
  left: 0;
  color: #606266;
  .a1 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 2px;
    background: #117afb;
    line-height: 40px;
    text-align: center;
    position: relative;
    i {
      position: absolute;
      top: 9px;
      right: 10px;
      cursor: pointer;
      font-size: 22px;
    }
  }
  .a2 {
    max-height: 180px;
    @extend .scrollbar1;
    box-sizing: border-box;
    padding: 20px 10px;
    font-size: 14px;
  }
  a {
    font-size: 14px;
    color: #606266;
  }
}
.zhaoinfoyuan {
  position: fixed;
  top: 50%;
  right: 20px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 14px;
  @extend .color;
  @extend .cflex;
  cursor: pointer;
}
.list-style {
  height: 200px;
  .list-body {
    height: 200px;
  }
  p {
    font-size: 14px;
  }
}
.seamless-warp {
  overflow: hidden;
  height: 200px;
  .item {
    & > div {
      padding: 12px 40px 12px 16px;
      cursor: pointer;
      @extend .ell;
    }
    .title {
      @extend .ell;
    }
  }
}
.hots{left:unset;right:0;}
.addcss1{height: 200px;overflow: hidden;}
.addcss3{width: 140px;
  .a1{margin-bottom: 0;}
  &>div{margin-left: -20px;}
}
.addcss2{height:0;}
@keyframes saoma {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
</style>
