<template>
  <div class="container">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" class="t">
        <template slot="title">
          <img src="../assets/img/pcimg31.png" alt="" class="a1" />
          <div class="a2">教育经历(请填写最高学历)</div>
        </template>
        <div class="ctx">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm1"
            class="ruleForm"
          >
            <div v-for="(item, index) in ruleForm.getData" :key="item + index">
              <div
                v-if="!zhankai && showNum == index"
                style="
                  background: #fcfcfd;
                  border: 1px solid #f1f2f4;
                  padding: 20px;
                "
              >

                <el-form-item
                  label="就学时间"
                  :prop="'getData.' + index + '.schoolTime'"
                  :rules="rules.schoolTime1"
                  class="addls1"
                >
                  <!-- <el-date-picker
                    v-model="item.schoolTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker> -->
                  <div style="display: flex">
                    <el-date-picker
                      v-model="item.schoolTime[0]"
                      type="date"
                      placeholder="开始日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <div style="margin:0 30px;">至</div>
                    <el-date-picker
                      v-model="item.schoolTime[1]"
                      type="date"
                      placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item
                  label="学校名称"
                  :prop="'getData.' + index + '.school'"
                  :rules="rules.school"
                >
                  <el-input
                    v-model="item.school"
                    placeholder="请输入学校名称"
                    clearable
                  ></el-input>
                </el-form-item>
                <div class="ls3">
                  <el-form-item
                    label="学位/学历"
                    :prop="'getData.' + index + '.degree'"
                    :rules="rules.degree"
                  >
                    <el-select
                      v-model="item.degree"
                      placeholder="请选择学位学历"
                      clearable
                    >
                      <el-option
                        v-for="aitem in eduOptions"
                        :key="aitem"
                        :value="aitem"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="就学专业 "
                    :prop="'getData.' + index + '.major'"
                    :rules="rules.major"
                  >
                    <el-input
                      v-model="item.major"
                      placeholder="请输入就学专业"
                      clearable
                    ></el-input>
                  </el-form-item>
                </div>
                <el-form-item
                  label="专业描述"
                  :prop="'getData.' + index + '.professional'"
                >
                  <el-input
                    v-model="item.professional"
                    placeholder="请输入专业描述"
                    clearable
                    type="textarea"
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <div>
                  <el-button type="primary" @click="submitForm('ruleForm1')"
                    >确认</el-button
                  >
                  <el-button @click="reset">取消</el-button>
                </div>
              </div>

              <div v-if="zhankai || showNum !== index" class="zhanshi">
                <div class="posi">
                  <i class="el-icon-edit pa1" @click="edit(index)"></i>
                  <i class="el-icon-delete pa1 pa2" @click="del(index)"></i>
                </div>
                <div class="a1">
                  <div class="b1">就学时间</div>
                  <div class="b2">
                    {{ item.schoolTime[0] }} ~ {{ item.schoolTime[1] }}
                  </div>
                </div>
                <div class="a1">
                  <div class="b1">学校名称</div>
                  <div class="b2">{{ item.school }}</div>
                </div>
                <div class="a1">
                  <div class="b1">学位/学历</div>
                  <div class="b2">{{ item.degree }}</div>
                </div>
                <div class="a1">
                  <div class="b1">就学专业</div>
                  <div class="b2">{{ item.major }}</div>
                </div>
                <div class="a1">
                  <div class="b1">专业描述</div>
                  <div class="b2">{{ item.professional }}</div>
                </div>
              </div>
            </div>
          </el-form>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm2"
            class="ruleForm"
          >
            <div v-for="(item, index) in ruleForm.formData" :key="index">
              <el-form-item
                label="就学时间"
                :prop="'formData.' + index + '.schoolTime'"
                :rules="rules.schoolTime"
                class="addls1"
              >
                <!-- <el-date-picker
                  v-model="item.schoolTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker> -->
                <div style="display: flex">
                  <el-date-picker
                    v-model="item.startTime"
                    type="date"
                    placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <div style="margin:0 30px;">至</div>
                  <el-date-picker
                    v-model="item.endTime"
                    type="date"
                    placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item
                label="学校名称"
                :prop="'formData.' + index + '.school'"
                :rules="rules.school"
              >
                <el-input
                  v-model="item.school"
                  placeholder="请输入学校名称"
                  clearable
                ></el-input>
              </el-form-item>
              <div class="ls3">
                <el-form-item
                  label="学位/学历"
                  :prop="'formData.' + index + '.degree'"
                  :rules="rules.degree"
                >
                  <el-select
                    v-model="item.degree"
                    placeholder="请选择学位学历"
                    clearable
                  >
                    <el-option
                      v-for="aitem in eduOptions"
                      :key="aitem"
                      :value="aitem"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="就学专业 "
                  :prop="'formData.' + index + '.major'"
                  :rules="rules.major"
                >
                  <el-input
                    v-model="item.major"
                    placeholder="请输入就学专业"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item
                label="专业描述"
                :prop="'formData.' + index + '.professional'"
              >
                <el-input
                  v-model="item.professional"
                  placeholder="请输入专业描述"
                  clearable
                  type="textarea"
                  maxlength="200"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div style="text-align: right">
                <el-button type="primary" @click="submitForm1('ruleForm2')"
                  >保存并新增</el-button
                >
                <el-button @click="reset1">取消</el-button>
              </div>
            </div>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "cv5",
  props: {
    jiaoyu: Array,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (
        !this.ruleForm.formData[0].startTime ||
        !this.ruleForm.formData[0].endTime
      ) {
        callback(new Error("就学时间不能为空"));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (
        !value[0] ||
        !value[1]
      ) {
        callback(new Error("就学时间不能为空"));
      } else {
        callback();
      }
    };
    return {
      activeNames: ["1"],
      showNum: 0,
      zhankai: true, //true就只是展示，false就是能编辑的
      eduOptions: [
        "小学",
        "初中",
        "高中",
        "职业高中",
        "大专",
        "大学本科",
        "研究生",
        "博士",
        "博士后",
      ],
      ruleForm: {
        formData: [
          {
            schoolTime: "",
            school: "",
            degree: "",
            major: "",
            professional: "",
            startTime: "",
            endTime: "",
          },
        ],
        getData: [],
      },
      rules: {
        // schoolTime: [
        //   {
        //     required: true,
        //     message: "就学时间不能为空",
        //     trigger: "blur",
        //   },
        // ],
        schoolTime: [
          { validator: validatePass, trigger: "change", required: true },
        ],
        schoolTime1: [
          { validator: validatePass1, trigger: "change", required: true },
        ],
        school: [
          {
            required: true,
            message: "学校名称不能为空",
            trigger: "blur",
          },
        ],
        degree: [
          {
            required: true,
            message: "学位学历不能为空",
            trigger: "change",
          },
        ],
        major: [
          {
            required: true,
            message: "就学专业不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      // this.ruleForm.getData
      for (let i = 0; i < this.jiaoyu.length; i++) {
        this.ruleForm.getData.push({
          id: this.jiaoyu[i].id,
          schoolTime: this.jiaoyu[i]["schoolTime"],
          school: this.jiaoyu[i]["school"],
          degree: this.jiaoyu[i]["degree"],
          major: this.jiaoyu[i]["major"],
          professional: this.jiaoyu[i]["professional"],
          startTime:this.jiaoyu[i]["schoolTime"][0],
          endTime:this.jiaoyu[i]["schoolTime"][1],
        });
      }
    }, 1000);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.$axios
            .post("/Api/user/saveEducational", {
              token: localStorage.getItem("token"),
              educational: this.ruleForm.getData,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message.success("修改成功");
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
          this.zhankai = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.getData.push({
            id: "",
            // schoolTime: this.ruleForm.formData[0]["schoolTime"],
            schoolTime: [
              this.ruleForm.formData[0]["startTime"],
              this.ruleForm.formData[0]["endTime"],
            ],
            school: this.ruleForm.formData[0]["school"],
            degree: this.ruleForm.formData[0]["degree"],
            major: this.ruleForm.formData[0]["major"],
            professional: this.ruleForm.formData[0]["professional"],
          });
          this.$refs.ruleForm2.resetFields();
          // this.$message({
          //   message: "保存并新增成功",
          //   type: "success",
          // });
          this.$axios
            .post("/Api/user/saveEducational", {
              token: localStorage.getItem("token"),
              educational: this.ruleForm.getData,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message.success(res.data.message);
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset() {
      this.zhankai = true;
    },
    reset1() {
      this.$refs.ruleForm2.resetFields();
    },
    edit(e) {
      this.zhankai = false;
      this.showNum = e;
    },
    del(e) {
      this.ruleForm.getData.splice(e, 1);
      this.$axios
        .post("/Api/user/saveEducational", {
          token: localStorage.getItem("token"),
          educational: this.ruleForm.getData,
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.$message.success("删除成功");
          } else {
            this.$message({
              message: res.data.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 600px;
  .t {
    .a2 {
      color: #646464;
      font-size: 24px;
      margin-left: 6px;
    }
  }
  /deep/ .el-collapse {
    border: none;
    .el-collapse-item__wrap,
    .el-collapse-item__header {
      border: none;
    }
    .el-collapse-item__arrow {
      font-size: 20px;
    }
    .el-collapse-item__content {
      margin-bottom: 25px;
    }
  }
}
.ctx {
  margin-top: 50px;
  .titles {
    color: #323232;
    font-size: 14px;
    margin-bottom: 16px;
  }
  /deep/ .el-textarea__inner {
    min-height: 120px !important;
  }
}
.zhanshi {
  background: #fcfcfd;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #f1f2f4;
  position: relative;
  .posi {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    .pa1 {
      width: 24px;
      height: 24px;
      background: #409eff;
      @extend .cflex;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
    }
    .pa2 {
      margin-left: 10px;
    }
  }
  .a1 {
    color: #898989;
    font-size: 14px;
    display: flex;
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 8px;
    }
    .b1 {
      margin-right: 20px;
    }
    .b2 {
      color: #292e41;
    }
  }
}
.addls1 {
  /deep/ .el-form-item__label {
    display: block;
    float: unset;
    text-align-last: left;
  }
  /deep/ .el-date-editor {
    width: 100%;
  }
}
.ls3 {
  @extend .fsb;
  /deep/ .el-form-item {
    width: 260px;
  }
  /deep/ .el-select {
    width: 100%;
  }
}
/deep/ .el-textarea__inner {
  min-height: 120px !important;
}
/deep/ .el-button:focus,
.el-button:hover {
  color: #606266;
  border-color: #dcdfe6;
  background-color: #fff;
}
/deep/ .el-button--primary:focus,
.el-button--primary:hover {
  background: #409eff;
  border-color: #409eff;
  color: #fff;
}
</style>
