import Vue from "vue"
import Router from "vue-router"
import notfount from '@/components/404'
import Index from './views/Index.vue' //首页文件
import Login from './views/Login.vue' //登录页面
import Campus from './views/Campus.vue' //校园招聘
import CampusContent from './views/CampusContent.vue' //校园招聘内页
import Society from './views/Society.vue' //社会招聘 
import SocietyContent from './views/SocietyContent.vue' //社会招聘内页 
import Internal from './views/Internal.vue' //有奖推荐
import InternalContent from './views/InternalContent.vue' //有奖推荐内页 
import Master from './views/Master.vue' //硕博专栏
import MasterContent from './views/MasterContent.vue' //硕博专栏内页
import About from './views/About.vue' //关于豪美
import Join from './views/Join.vue' //人在豪美 
import Register from './views/Register.vue' //注册页面 
import Serve from './views/Serve.vue' //服务协议页面 
import Privacy from './views/Privacy.vue' //隐私协议页面  
import Forgot from './views/Forgot.vue' //忘记密码页面
import Info from './views/Info.vue' //个人信息页面  
import Personal from './views/Personal.vue' //个人中心页面
import Cv from './views/Cv.vue' //创建简历页面
import Yulan from './views/Yulan.vue' //简历预览页面


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  // mode:'history',
  routes:[
    {
      path:'',
      redirect:'/index'  //重定向
    },
    {
      path:'/index',
      name:'index',
      component:Index
    },
    {
      path:'/login',
      name:'login',
      component:Login
    },
    {
      path:'/campus',
      name:'campus',
      component:Campus
    },
    {
      path:'/campus-content',
      name:'campus-content',
      component:CampusContent
    },
    {
      path:'/society',
      name:'society',
      component:Society
    },
    {
      path:'/society-content',
      name:'society-content',
      component:SocietyContent
    },
    {
      path:'/internal',
      name:'internal',
      component:Internal
    },
    {
      path:'/internal-content',
      name:'internal-content',
      component:InternalContent
    },
    {
      path:'/master',
      name:'master',
      component:Master
    },
    {
      path:'/master-content',
      name:'master-content',
      component:MasterContent
    },
    {
      path:'/join',
      name:'join',
      component:Join
    },
    {
      path:'/about',
      name:'about',
      component:About
    },
    {
      path:'/register',
      name:'register',
      component:Register
    },
    {
      path:'/privacy',
      name:'privacy',
      component:Privacy
    },
    {
      path:'/serve',
      name:'serve',
      component:Serve
    },
    {
      path:'/forgot',
      name:'forgot',
      component:Forgot
    },
    {
      path:'/info',
      name:'info',
      component:Info
    },
    {
      path:'/personal',
      name:'personal',
      component:Personal
    },
    {
      path:'/cv',
      name:'cv',
      component:Cv
    },
    {
      path:'/yulan',
      name:'yulan',
      component:Yulan
    },
    {
      path: '*',
      name: 'notfount',
      component: notfount
    }
  ]
})