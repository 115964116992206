import router from '../router'
import { MessageBox } from 'element-ui';

export const Host = 'https://zp.haomei-alu.com';

export const phoneHost = 'https://zp.haomei-alu.com/#';

export const imgHost = 'https://zp.haomei-alu.com';



export function Application(){
  let islogs = localStorage.getItem('Logins')
  if(!islogs){
    MessageBox.confirm("当前用户尚未登录，不能申请岗位哦", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      router.push("/login");
    }).catch(()=>{});
  }else{
    // router.push('/campus')
  }
}

export function Collect(){
  let islogs = localStorage.getItem('Logins')
  if(!islogs){
    MessageBox.confirm("当前用户尚未登录，不能收藏岗位哦", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      // MessageBox({
      //   type: "success",
      //   message: "点击了确定，跳转到登录页面!",
      // });
      router.push("/login");
    }).catch(()=>{});
  }
}

// 退出登录
export function outLogin(){
  localStorage.removeItem("Logins");
  localStorage.removeItem("Phone");
  localStorage.removeItem('token')
  router.push("/index");
}

//判断是否登录，没登录返回登录页面
export function isLogins(){
  let islogs = localStorage.getItem('Logins')
  if(!islogs){
    router.push('/login')
  }
}