<template>
  <div>
    <div class="banner">
      <img :src="dataImgs" alt="" />
    </div>
    <div class="swipers">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in swiperDatas" :key="index">
          <div class="ls" v-for="(it, ins) in item" :key="ins">
            <div class="les">
              <img :src="webHost + it.img" alt="" class="img1" />
              <img src="../assets/img/pcimg28.png" alt="" class="img2" />
            </div>
            <div class="ris">
              <div class="a1">{{ it.subtitle }}</div>
              <div class="a2">
                <div class="b1">{{ it.introduction }}</div>
                <div class="b2">{{ it.url }}</div>
              </div>
              <div class="a3">
                {{ it.remark }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
import { Host, imgHost } from "../comjs/com";
export default {
  name: "Join",
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 5500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 800,
        autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperDatas: [],
      webHost: Host,
      imgHost: imgHost,
      dataImgs: "",
    };
  },
  mounted() {
    // this.$axios.post("/Api/photo/getphoto", { id: 4 }).then((res) => {
    //   let datas = res.data.data.content;
    //   let pages = [];
    //   datas.forEach((item, index) => {
    //     const page = Math.floor(index / 2);
    //     if (!pages[page]) {
    //       pages[page] = [];
    //     }
    //     pages[page].push(item);
    //   });
    //   this.swiperDatas = pages;
    // });
    this.$axios
      .all([
        this.$axios.post("/Api/photo/getphoto", { id: 4 }),
        this.$axios.post("/Api/photo/getphoto", { id: 5 }),
      ])
      .then(
        this.$axios.spread((res1, res2) => {
          let datas = res1.data.data.content;
          let pages = [];
          datas.forEach((item, index) => {
            const page = Math.floor(index / 2);
            if (!pages[page]) {
              pages[page] = [];
            }
            pages[page].push(item);
          });
          this.swiperDatas = pages;

          this.dataImgs = this.webHost + res2.data.data.content[2].img;
        })
      );
  },
};
</script>

<style lang="scss" scoped>
.banner {
  @extend .minwid;
  img {
    display: block;
    width: 100%;
  }
}
.swipers {
  @extend .minwid;
  @extend .tutWid;
  padding: 115px 0;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    @media only screen and (max-width: 1420px) {
      display: none;
    }
    background: #f8f8f8;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #4c4c4c;
    font-size: 20px;
    @extend .cflex;
    &:hover {
      @extend .bg;
      color: #fff;
    }
  }
  .swiper-button-prev {
    left: -110px;
  }
  .swiper-button-next {
    right: -110px;
  }
}
.ls {
  @extend .fsb;
  align-items: flex-start;
  padding-bottom: 40px;
  &:not(:nth-of-type(1)) {
    padding-top: 95px;
  }
  &:nth-of-type(2) {
    flex-direction: row-reverse;
    .les {
      margin-right: 38px;
      .img2 {
        right: -38px;
        left: unset;
      }
    }
  }
  .les {
    width: 500px;
    height: 320px;
    position: relative;
    margin-left: 38px;
    .img1 {
      @extend .imgcover;
    }
    .img2 {
      position: absolute;
      bottom: -40px;
      left: -38px;
      z-index: -1;
    }
  }
  .ris {
    width: 600px;
    .a1 {
      color: #4a4a4a;
      font-size: 22px;
      font-weight: bold;
      margin: 30px 0 40px;
    }
    .a2 {
      display: flex;
      align-items: center;
      color: #4a4a4a;
      font-size: 16px;
      .b1 {
        padding-right: 16px;
        margin-right: 16px;
        border-right: 1px solid #4a4a4a;
      }
    }
    .a3 {
      color: #545664;
      font-size: 16px;
      line-height: 2;
      margin-top: 35px;
    }
  }
}
/deep/ .swiper-pagination {
  bottom: 40px;
  @extend .cflex;
  z-index: 3;
  width: 100%;
  position: unset;
  margin-top: 80px;
  .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    background: #cacaca;
    opacity: 0.6;
    border-radius: 50%;
    margin: 0 10px;
    &-active {
      width: 30px;
      border-radius: 20px;
      opacity: 1;
      background: #117afb !important;
    }
  }
}
</style>
