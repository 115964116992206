<template>
  <div class="container">
    <div class="conts">
      <div class="les">
        <router-link class="ls" to="/campus">校园招聘</router-link>
        <router-link class="ls" to="/society">社会招聘</router-link>
        <router-link class="ls" to="/internal">有奖推荐</router-link>
        <router-link class="ls" to="/master">硕博专栏</router-link>
        <router-link class="ls" to="/join">人在豪美</router-link>
        <a href="http://www.haomei-alu.com/" class="ls" target="_blank">豪美官网</a>
      </div>
      <div class="ris">Copyright © 2020-2022 Haomei All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foots",
};
</script>

<style lang="scss" scoped>
.container {
  background: #0b1531;
  height: 80px;
  @extend .minwid;
  .conts {
    @extend .fsb;
    @extend .tutWid;
    height: 100%;
  }
  .les {
    display: flex;
    .ls {
      color: #fff;
      font-size: 14px;
      margin-right: 50px;
      &:hover {
        @extend .color;
      }
    }
  }
  .ris {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }
}
</style>
