<template>
  <div class="ris">
    <div class="ls">
      <div class="a1">热招岗位</div>
      <div class="conta2">
        <div class="a2" v-for="(item, index) in getdatas" :key="index">
          <router-link
            :to="{ path: geturl + '-content', query: { id: item.id } }"
            class="b1"
            >{{ item.title }}</router-link
          >
          <div class="b2">{{ item.createTime | tmoment("YYYY-MM-DD") }}</div>
        </div>
      </div>
      <el-button plain class="a3" @click="mores">更多</el-button>
    </div>
    <div class="ls ls1">
      <div class="a1">相似岗位</div>
      <div class="conta2">
        <div class="a2" v-for="(item, index) in getdatas1" :key="index">
          <router-link
            :to="{ path: geturl + '-content', query: { id: item.id } }"
            class="b1"
            >{{ item.title }}</router-link
          >
          <div class="b2">{{ item.createTime | tmoment("YYYY-MM-DD") }}</div>
        </div>
      </div>
      <!-- <el-button plain class="a3" @click="huan">换一批</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Content_Left",
  props: {
    geturl: String,
    cateid: Number,
  },
  data() {
    return {
      getdatas: "",
      getdatas1: "",
    };
  },
  mounted() {
    this.$axios
      .post("/Api/post/postsList", {
        cateid: this.cateid,
        limit: 5,
        ishot: 1,
      })
      .then((res) => {
        this.getdatas = res.data.data.data;
      });

    this.$axios
      .post("/Api/post/postsList", {
        cateid: this.cateid,
        limit: 5,
        ishot: 2,
      })
      .then((res) => {
        this.getdatas1 = res.data.data.data;
      });
  },
  methods: {
    mores() {
      this.$router.push(this.geturl);
    },
    huan() {
      console.log("换");
    },
  },
};
</script>

<style lang="scss" scoped>
.ris {
  width: 334px;
  .ls {
    height: 290px;
    background: #fff;
    box-sizing: border-box;
    padding: 0 20px;
    width: 334px;

    .a1 {
      line-height: 40px;
      font-size: 14px;
      font-weight: 700;
    }
    .conta2 {
      margin: 20px 0 10px;
      color: #898989;
    }
    .a2 {
      @extend .fsb;
      height: 30px;
      align-items: flex-start;
      .b1 {
        color: #148ef5;
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
      }
      .b2 {
        color: #767c93;
        font-size: 14px;
      }
    }
    .a3 {
      display: block;
      margin: 0 auto;
    }
  }
  .ls1 {
    margin-top: 20px;
  }
}
</style>
