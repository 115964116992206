<template>
  <div class="container">
    <div class="conts">
      <div class="les">
        <img :src="dataImg" alt="" class="a1" />
        <div class="a2">
          <img src="../assets/img/pcimg19.jpg" alt="" class="b1" />
          <div class="b2">扫码关注豪美招聘</div>
        </div>
      </div>
      <div class="ris">
        <div class="conta">
          <div class="title">求职者找回密码</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
            <el-form-item prop="phone">
              <el-input placeholder="输入手机号" v-model="ruleForm.phone">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="verify" class="ls">
              <el-input placeholder="手机验证码" v-model="ruleForm.verify" class="a1">
              </el-input>
              <el-button
                :disabled="disabled1"
                type="primary"
                @click="getVerify"
                class="a2 addcss0817"
                ><span v-if="timesHide">{{ times }}</span
                >{{ verkey }}</el-button
              >
            </el-form-item>
            <el-form-item label="" prop="password1">
              <el-input
                placeholder="请输入新密码"
                v-model="ruleForm.password1"
                type="password"
              ></el-input>
            </el-form-item>

            <el-form-item class="ls3">
              <el-button
                :disabled="disabled2"
                type="primary"
                @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
            <div class="ls4">
              <router-link to="/login">登录</router-link>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Host } from "../comjs/com";
export default {
  name: "Forgot",
  data() {
    let validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空!"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      }
      callback();
    };
    let vpass = (rule, value, callback) => {
      // let reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/;
      if (!value) {
        callback(new Error("密码不能为空!"));
      }
      // if (!reg.test(value)) {
      //   callback(new Error("密码长度必须在8位以上，含大小写字母、数字、符号至少3种"));
      // }
      callback();
    };
    return {
      disabled1: true,
      disabled2: true,
      verkey: "获取验证码", //秒后重新发送
      times: 60,
      timesHide: false,
      ruleForm: {
        phone: "",
        verify: "",
        password1: "",
      },
      rules: {
        phone: [
          {
            validator: validatorPhone,
            trigger: "blur",
          },
        ],
        verify: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        password1: [
          {
            validator: vpass,
            trigger: "blur",
          },
        ],
      },
      Host: Host,
      dataImg: "",
    };
  },
  mounted() {
    this.$axios.post("/Api/photo/getphoto", { id: 5 }).then((res) => {
      this.dataImg = this.Host + res.data.data.content[3].img;
    });
  },
  computed: {
    watchMore() {
      var phone = this.ruleForm.phone;
      var verify = this.ruleForm.verify;
      var password1 = this.ruleForm.password1;
      return { phone, verify, password1 };
    },
  },
  methods: {
    getVerify() {
      if (
        this.ruleForm["phone"] !== "" &&
        /^1[3456789]\d{9}$/.test(this.ruleForm["phone"])
      ) {
        // 手机号码格式正确且不为空，可以发送短信
        this.timesHide = true;
        this.verkey = "秒后重新发送";
        this.disabled1 = true;
        this.interval = setInterval(this.countDown, 1000);
        this.$axios
          .post("/Api/user/getCode", {
            phone: this.ruleForm.phone,
            type: 2,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$message(res.data.message);
            } else if (res.data.code == 1) {
              this.$message.success(res.data.message);
            } else if (res.data.code == 2) {
              this.$message(res.data.message);
            }
          });
      }
    },
    countDown() {
      let that = this;
      that.times--;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/Api/user/changePassword", {
              phone: this.ruleForm.phone,
              password: this.ruleForm.password1,
              code: this.ruleForm.verify,
            })
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: "修改成功，请去登录",
                  type: "success",
                  duration: 1500,
                });
                setTimeout(() => {
                  this.$router.push("/login");
                }, 1800);
              } else {
                this.$message({
                  message: res.data.message,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {
    "ruleForm.phone": function (newVar) {
      if (newVar !== "") {
        if (this.times == 60) {
          this.disabled1 = false;
        }
      } else {
        this.disabled1 = true;
      }
    },
    watchMore: function (newVar) {
      if (newVar.phone !== "" && newVar.verify !== "" && newVar.password1 !== "") {
        this.disabled2 = false;
      } else {
        this.disabled2 = true;
      }
    },
    times: function (newVal) {
      if (newVal == 0) {
        this.verkey = "获取验证码";
        this.disabled1 = false;
        this.times = 60;
        this.timesHide = false;
        clearInterval(this.interval);
      } else {
        this.times = newVal;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f4f8;
  min-height: calc(100vh - 180px);
  @extend .minwid;
  overflow: hidden;
}
.conts {
  width: 1000px;
  height: 600px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(38, 89, 203, 0.06);
  margin: 80px auto;
  display: flex;
  overflow: hidden;
}
.les {
  width: 500px;
  .a1 {
    display: block;
    height: 380px;
  }
  .a2 {
    height: 220px;
    width: 100%;
    @extend .cflex;
    flex-direction: column;
    background: #cbdbee;
    .b2 {
      margin-top: 12px;
      color: #323232;
      font-size: 14px;
    }
  }
}
.ris {
  box-sizing: border-box;
  width: 500px;
  @extend .cflex;
  padding: 0 70px;
  .conta {
    width: 360px;
  }
  .title {
    color: #505050;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .ls {
    /deep/ .el-form-item__content {
      .a1 {
        width: 200px;
        margin-right: 10px;
      }
    }
  }
  .ls1 {
    margin-bottom: 0;
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
      line-height: unset;
    }
    /deep/ .el-checkbox {
      display: flex;
      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
    .a1 {
      margin-top: -2px;
      margin-left: 10px;
      color: #787878;
      font-size: 12px;
      a {
        @extend .color;
        font-size: 12px;
      }
    }
  }
  .ls2 {
    margin-bottom: 0;
    .a1 {
      color: #767c93;
      font-size: 12px;
    }
  }
  .ls3 {
    /deep/ .el-button {
      width: 100%;
    }
  }
  .ls4 {
    @extend .cflex;
    justify-content: flex-end;
    margin-top: 20px;
    a {
      color: #767c93;
      font-size: 14px;
    }
  }
}
.addcss0817{width: 150px;}
/deep/ .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .el-form-item.is-error .el-input__inner {
  border-color: #dcdfe6;
}
/deep/ .el-button.is-disabled {
  color: #babdc9;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}
/deep/ .el-button--primary {
  background-color: #148ef5;
}
</style>
